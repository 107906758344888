import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import ImportPbModal from "./importPbModal";

const AddCalendarRegion = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [name, setName] = useState("");
  const [status, setStatus] = useState(true);

  const [errorMessage, setErrorMessage] = useState("");
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [importPbModal, setImportPbModal] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const toggleImportPbModal = () => {
    setImportPbModal(!importPbModal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setErrorMessage("The name of the region must be filled");
    } else {
      let data = {
        name,
        status,
      };
      axios
        .post(`/api/calendar/regions`, isNew ? data : { id, ...data }, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          if (result.data.success) {
            navigate("/app/calendarregions");
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  const handleDelete = () => {
    axios
      .delete(`/api/calendar/region/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.data.success) {
          navigate("/app/calendarregions");
        }
      });
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/calendar/region/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result;

          setName(data.name);
          setStatus(data.status);
          setLoading(false);
        });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{isNew ? "New" : "Update"} Calendar Region</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="8">
                        <FormGroup row>
                          <Label sm="3">Region Name</Label>
                          <Colxx sm="9">
                            <Input
                              type="text"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <Switch
                          className="custom-switch custom-switch-primary-inverse"
                          checked={status}
                          onChange={(value) => setStatus(value)}
                        />
                      </Col>
                      {!isNew && (
                        <Col xs="2">
                          <Button onClick={toggleImportPbModal}>
                            PB Import
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="light"
            className="top-right-button "
            onClick={() => navigate("/app/calendarregions")}
          >
            Cancel
          </Button>
          {!isNew && (
            <Button
              color="danger"
              className="top-right-button ml-2"
              onClick={handleDelete}
            >
              Delete
            </Button>
          )}
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        <div>{errorMessage}</div>
        {importPbModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <ImportPbModal toggle={toggleImportPbModal} regionId={id} />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default AddCalendarRegion;
