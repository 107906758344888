import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import { useToken } from "auth/useToken";
import Papa from "papaparse";
import { toast } from "react-toastify";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import moment from "moment";

const SaveBulkResults = ({ toggle, setCandidateNos, test, setTest }) => {
  const [results, setResults] = useState([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [bx, setBx] = useState(false);

  // let d = new Date();
  // d.setDate(d.getDate() - 13);
  // const [test, setTest] = useState(d);

  const [token, setToken] = useToken();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      if (errorMessage === "") {
        axios
          .post(
            `/api/save-results`,
            { results, bx, testDate: moment(test).format("DD/MM/YYYY") },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            setSent(true);
            setSending(false);
            toast.success("Successfully saved");
          })
          .catch((err) => {
            setErrorMessage(err.response.data);
            toast.error(err.response.data);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form className="text-left">
                  <Row>
                    <Col md="12">
                      <Row>
                        Please, upload the .CSV file of results: &nbsp;
                        <Switch
                          className="custom-switch custom-switch-primary-inverse custom-switch-small"
                          checked={bx}
                          size="small"
                          onChange={(value) => setBx(value)}
                        />
                        &nbsp;
                        <Label>{bx ? " BX" : " PB"}</Label>
                      </Row>
                      <FormGroup row>
                        <Colxx sm="6">
                          <Input
                            type="file"
                            accept=".csv"
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files) {
                                setCandidatesLoading(true);
                                Papa.parse(files[0], {
                                  delimiter: ",",
                                  header: true,
                                  skipEmptyLines: true,
                                  transformHeader: function (h) {
                                    return h.replace(/ /g, "");
                                  },
                                  transform: function (h) {
                                    return h.replace(/[="\\]/g, "");
                                  },
                                  complete: function (results) {
                                    console.log("Finished:");
                                    console.log(results.data);
                                    setResults(results.data);
                                    setCandidateNos(
                                      results.data.map((obj) =>
                                        bx
                                          ? obj.CandidateNo
                                          : obj.CandidateNumber
                                      )
                                    );
                                    console.log(
                                      results.data.map((obj) =>
                                        bx
                                          ? obj.CandidateNo
                                          : obj.CandidateNumber
                                      )
                                    );
                                    setCandidatesLoading(false);
                                  },
                                });
                              }
                            }}
                          />
                        </Colxx>
                        <Colxx sm="6">
                          <Label>Test Date</Label> &nbsp;
                          <DatePicker
                            className={"custom-datepicker"}
                            calendarIcon={
                              <i className="simple-icon-calendar" />
                            }
                            clearIcon={<i className="iconsminds-close" />}
                            dayPlaceholder="DD"
                            monthPlaceholder="MM"
                            yearPlaceholder="YYYY"
                            format="dd/MM/y"
                            value={test}
                            showLeadingZeros={true}
                            onChange={(v) => {
                              console.log(v);
                              setTest(v);
                            }}
                          />
                        </Colxx>
                      </FormGroup>
                      {/* <>{JSON.stringify(candidates)}</> */}
                      {candidatesLoading ? (
                        <p>Loading...</p>
                      ) : (
                        <p>The number of results: {results.length}</p>
                      )}
                      {sending && (
                        <span className="btn btn-warning">Uploading...</span>
                      )}
                      {sent && <span className="btn btn-success">DONE!</span>}
                    </Col>
                  </Row>
                </Form>
                <div className="mt-3">
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={() => toggle()}
                  >
                    Close
                  </Button>
                  {!sent && (
                    <Button
                      color="primary"
                      className="ml-2 top-right-button"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  )}
                </div>
                &nbsp;
                <div>{errorMessage}</div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default SaveBulkResults;
