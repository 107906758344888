import React from "react";
import { useDroppable } from "@dnd-kit/core";

const Droppable = ({ droppedItem, uniqueId, onDrop }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: uniqueId,
  });
  let backgroundColor = "#f2f2f2";
  if (isOver) {
    backgroundColor = "darkgreen";
  }
  return (
    <div ref={setNodeRef} style={{ backgroundColor }} className="droppable">
      {droppedItem && <i>{droppedItem}</i>}
    </div>
  );
};

export default Droppable;
