const data = [
  {
    id: "regions",
    icon: "iconsminds-check",
    label: "Regions",
    to: `/app/regions`,
    access: ["Admin", "Manager", "User"],
  },
  // {
  //   id: "ieltsenquiries",
  //   icon: "iconsminds-profile",
  //   label: "IELTS Enquiries",
  //   to: `/app/ieltsenquiries`,
  //   access: ["Admin", "Manager", "User"],
  // },
  {
    id: "ieltspartners",
    icon: "iconsminds-handshake",
    label: "IELTS Partners",
    to: `/app/ieltspartners`,
    access: ["Admin", "Manager"],
  },
  // {
  //   id: "evidences",
  //   icon: "iconsminds-receipt-4",
  //   label: "Payment Evidences",
  //   to: `/app/evidences`,
  //   access: ["Admin", "Manager", "User"],
  // },
  {
    id: "emails",
    icon: "iconsminds-email",
    label: "Emails",
    to: `/app/emails`,
    access: ["Admin", "Manager"],
  },
  {
    id: "speaking",
    icon: "iconsminds-speach-bubbles",
    label: "Speaking",
    to: `/app/speaking`,
    access: ["Admin", "Manager"],
  },
  {
    id: "calendarregions",
    icon: "iconsminds-calendar-1",
    label: "Calendar",
    to: `/app/calendarregions`,
    access: ["Admin", "Manager"],
  },
  {
    id: "invigilators",
    icon: "iconsminds-conference",
    label: "Invigilators",
    to: `/app/invigilators`,
    access: ["Admin", "Manager"],
  },
  {
    id: "eor",
    icon: "iconsminds-arrow-refresh",
    label: "EOR",
    to: `/app/eor`,
    access: ["Admin", "Manager"],
  },
  {
    id: "broadcast",
    icon: "iconsminds-megaphone",
    label: "Broadcast",
    to: `/app/broadcast`,
    access: ["Admin", "Manager"],
  },
  {
    id: "sms",
    icon: "iconsminds-mail-send",
    label: "SMS",
    to: `/app/sms`,
    access: ["Admin", "Manager"],
  },
  {
    id: "details",
    icon: "iconsminds-information",
    label: "Details",
    to: `/app/details`,
    access: ["Admin", "Manager"],
  },
  {
    id: "inspect",
    icon: "iconsminds-magnifi-glass",
    label: "Inspect",
    to: `/app/inspect`,
    access: ["Admin", "Manager", "Inspector"],
  },
  {
    id: "certificate",
    icon: "iconsminds-diploma-2",
    label: "Certificate",
    to: `/app/certificate`,
    access: ["Admin", "Manager", "Inspector"],
  },
  {
    id: "users",
    icon: "iconsminds-conference",
    label: "Users",
    to: `/app/users`,
    access: ["Admin"],
  },
];
export default data;
