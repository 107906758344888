import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";

const EditVenue = (props) => {
  const {
    toggle,
    venueId,
    values,
    setValues,
    venuesOptions,
    setVenuesOptions,
  } = props;
  const user = useUser();

  const [thisValues, setThisValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleChange = (e) => {
    setUpdated(true);
    const { name, value } = e.target;
    setThisValues({
      ...thisValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!thisValues.name || !thisValues.placeId) {
      toast.error("Name and Venue ID cannot be empty");
    } else {
      axios
        .post(`/api/speaking/venue/${venueId}`, thisValues, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          // console.log(result);
          if (result.status === 200) {
            setSuccess(true);
            setValues({
              ...values,
              venueId: {
                name: thisValues.name,
                placeId: thisValues.placeId,
                _id: result.data._id,
              },
            });
            setVenuesOptions([
              ...venuesOptions,
              {
                label: thisValues.name,
                value: `${thisValues.placeId}|${result.data._id}`,
              },
            ]);
            toast.success(
              venueId ? "Successfully updated" : "Successfully added"
            );
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          // console.log(err.response.data);
          toast.error(Object.values(err.response.data.errors)[0]);
          toast.error("Something went wrong");
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    if (venueId) {
      axios
        .get(`/api/speaking/venue/${venueId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setThisValues(result.data);
          setLoading(false);
        })
        .catch((e) => {
          toast.error("something went wrong");
        });
    } else {
      // setValues(initialValues);
      setLoading(false);
    }
  }, [venueId]);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>{venueId ? "Update Details" : "Add Venue"}</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Name</Label>
                        <Input
                          type="text"
                          name="name"
                          value={thisValues.name}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Venue ID</Label>
                        <Input
                          type="text"
                          name="placeId"
                          value={thisValues.placeId}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>

                <div className="mt-3">
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={toggle}
                  >
                    Close
                  </Button>
                  {updated && (
                    <Button
                      color={success ? "success" : "primary"}
                      className="top-right-button ml-2"
                      onClick={handleSubmit}
                      disabled={success}
                    >
                      {success ? "Done" : `${venueId ? "Update" : "Add"}`}
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default EditVenue;
