import React, { useEffect, useState } from "react";
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import Draggable from "components/DndComponents/Draggable";
import Droppable from "components/DndComponents/Droppable";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Button,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";
import moment from "moment";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import AddWindowModal from "./addWindowModal";
import { dateDropToSlash, dateSlashToDrop } from "utils/stringFunctions";

const initialValues = {
  name: "",
  status: true,
  slots: [],
};

const EditSlotPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const [values, setValues] = useState(initialValues);
  const [candidates, setCandidates] = useState([]);
  const [slotWindows, setSlotWindows] = useState([]);
  const [addWindowModal, setAddWindowModal] = useState(false);
  const [changed, setChanged] = useState(false);
  const [sawWarning, setSawWarning] = useState(false);

  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleAddWindow = () => {
    toggleAddWindowModal();
  };

  const toggleAddWindowModal = () => {
    setAddWindowModal(!addWindowModal);
  };

  const handleDragEnd = (e) => {
    const { active, over } = e;
    let newSlots = [...slotWindows];
    if (over?.id && active?.id) {
      const activeId = active.id.split("-")[1];
      if (!newSlots[over.id].available) {
        toast.warn("This slot is not empty.");
      } else {
        newSlots[over.id].candidateId = { ...candidates[activeId] };
        newSlots[over.id].available = false;
        let tempCandidates = [...candidates];
        setSlotWindows(newSlots);
        tempCandidates.splice(activeId, 1);
        setCandidates(tempCandidates);
        setChanged(true);
      }
    }
  };

  const handleRemoveCandidate = (index) => {
    let newCandidates = [...candidates];
    newCandidates.unshift({ ...slotWindows[index].candidateId, changed: true });
    setCandidates(newCandidates);
    let newSlots = [...slotWindows];
    delete newSlots[index].candidateId;
    newSlots[index].available = true;
    setSlotWindows(newSlots);
    setChanged(true);
  };

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      axios
        .post(
          `/api/candidates-maintest`,
          {
            mainTestDate: values.mainTestDate,
            search: e.target.value.toLowerCase(),
            region: values.region,
            venue: values.venue,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((candidatesResult) => {
          setCandidates(candidatesResult.data.candidates);
          toast.success("Candidates list updated");
        });
    }
  };

  const handleAutoFill = () => {
    let newSlots = [...slotWindows];
    let tempCandidates = [...candidates];
    newSlots.forEach((window) => {
      if (window.available) {
        if (tempCandidates.length > 0) {
          window.candidateId = tempCandidates.shift();
          window.available = false;
        }
      }
    });
    setSlotWindows(newSlots);
    setCandidates(tempCandidates);
    setChanged(true);
  };

  const handleDownload = (type) => {
    setDownloadLoading(true);
    axios
      .post(
        `/api/speaking-download-${type}`,
        {
          mainTestDate: values.mainTestDate,
          examinerNumber: values.examinerId.staffId,
          sessionDate: dateSlashToDrop(values.speakingDate),
          venue: values.venueId.name,
        },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `${type}-${
          values.examinerId.staffId + "-" + values.speakingDate
        }.pdf`; //or any other extension
        document.body.appendChild(link);
        link.click();
        toast.success("The file is downloaded successfully.");
        setDownloadLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setDownloadLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let data = {
      ...values,
      windows: slotWindows,
      changedCandidates: candidates.filter((one) => one.changed),
    };
    axios
      .post(`/api/speaking/slot/${id}`, isNew ? data : { id, ...data }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setSubmitting(false);
        setChanged(false);
        toast.success("Successfully updated");
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/speaking/slot/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result;
          setLoading(false);
          setValues(data);
          setSlotWindows(data.windows);
          axios
            .post(
              `/api/candidates-maintest`,
              {
                mainTestDate: data.mainTestDate,
                region: data.region,
                venue: data.venue,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((candidatesResult) => {
              setCandidates(candidatesResult.data.candidates);
            });
        });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{isNew ? "New" : "Update"} Slot</h1>
              <div
                className="text-zero top-right-button-container"
                style={{ display: "flex" }}
              >
                <div>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      color="primary"
                      // className="btn-xs"
                      outline
                    >
                      Actions
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        disabled={downloadLoading}
                        onClick={() => handleDownload("single")}
                      >
                        {downloadLoading
                          ? "Loading..."
                          : "Examiner's Report - Single"}
                      </DropdownItem>
                      <DropdownItem
                        disabled={downloadLoading}
                        onClick={() => handleDownload("multi")}
                      >
                        {downloadLoading
                          ? "Loading..."
                          : "Examiner's Report - Multi"}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card className="mb-4 text-left">
                  <CardBody>
                    <Row>
                      <Col sm="6">
                        {" "}
                        <h5>
                          Examiner:{" "}
                          <strong>{values?.examinerId?.name || "N/A"}</strong>
                        </h5>
                        <h5>
                          Venue: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{values?.venueId?.name || "N/A"}</strong>
                        </h5>
                        <h5>
                          Format: &nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{values?.vcs ? "VCS" : "F2F"}</strong>
                        </h5>
                      </Col>
                      <Col sm="6">
                        <h5>
                          Region:
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{values?.region || "N/A"}</strong>
                        </h5>
                        <h5>
                          Main Test: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{values?.mainTestDate || "N/A"}</strong>
                        </h5>
                        <h5>
                          Speaking Test:{" "}
                          <strong>
                            {values?.speakingDate
                              ? dateSlashToDrop(values?.speakingDate)
                              : "N/A"}
                          </strong>
                        </h5>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Separator className="mt-2" />
              </Colxx>
              <Col md="6">
                <Row className="mt-3">
                  <h2 className="ml-4">
                    Slots{" "}
                    <span>
                      <i
                        className="simple-icon-plus ml-2 clickable"
                        onClick={() => handleAddWindow()}
                      />
                    </span>
                    <Button
                      color="primary"
                      className="top-right-button ml-2"
                      size="sm"
                      onClick={handleAutoFill}
                    >
                      Autofill
                    </Button>
                  </h2>
                  <Colxx xxs="12">
                    <Card className="mb-4">
                      <CardBody>
                        {slotWindows.map((window, index) => (
                          <Row key={index}>
                            <span className="list-item-heading mb-1 truncate d-flex flex-row">
                              <span style={{ transform: "scale(0.8)" }}>
                                <Switch
                                  className="custom-switch custom-switch-primary-inverse mr-2 xs"
                                  checked={!window.deleted}
                                  onChange={(value) => {
                                    if (window.candidateId) {
                                      toast.warning(
                                        "You cannot change the status - registered candidate"
                                      );
                                    } else {
                                      let tempWindows = [...slotWindows];
                                      tempWindows[index]["deleted"] = !value;
                                      tempWindows[index]["available"] = value;
                                      setSlotWindows(tempWindows);
                                      setChanged(true);
                                    }
                                  }}
                                />
                              </span>
                              {window.time}{" "}
                              {/* {window.candidateId && window.candidateId} */}
                              {!window.deleted && (
                                <span
                                  style={{ overflow: "hidden", clear: "both" }}
                                >
                                  <Droppable
                                    droppedItem={
                                      window.candidateId
                                        ? !window.available
                                          ? `${window.candidateId.CANDIDATE_NUMBER}: ${window.candidateId.GIVEN_NAME} ${window.candidateId.FAMILY_NAME}`
                                          : "drop here"
                                        : "drop here"
                                    }
                                    uniqueId={`${index}`}
                                  />
                                  <i
                                    className="iconsminds-remove ml-2 clickable"
                                    onClick={() => handleRemoveCandidate(index)}
                                  />
                                </span>
                              )}
                            </span>
                          </Row>
                        ))}
                      </CardBody>
                    </Card>
                  </Colxx>
                </Row>
              </Col>
              <Col md="6">
                <Row className="mt-3 ml-2">
                  <h2 className="ml-4 mr-2">
                    Candidates{" "}
                    {/* <span>
                    <i
                      className="simple-icon-plus ml-2 clickable"
                      onClick={() => handleClickSlot()}
                    />
                  </span> */}
                  </h2>
                  <span className="search-sm d-inline-block mb-1 align-top">
                    {/* <div className="d-block d-md-inline-block pt-1">
                <div className="search-sm d-inline-block mb-1 "> */}
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder={"Search"}
                      onKeyPress={(e) => onSearchKey(e)}
                    />
                    {/* </div> */}

                    {/* </div> */}
                  </span>
                  <Colxx xxs="12">
                    <Card>
                      <CardBody>
                        {candidates.length === 0 && "No data"}
                        {candidates.map((candidate, index) => (
                          <Row key={index}>
                            <span
                              className={`list-item-heading ${
                                candidate.changed && "changed-candidate"
                              }`}
                            >
                              <Draggable
                                name={`${candidate.CANDIDATE_NUMBER}: ${candidate.GIVEN_NAME} ${candidate.FAMILY_NAME}`}
                                uniqueId={`item-${index}`}
                              />
                            </span>
                          </Row>
                        ))}
                      </CardBody>
                    </Card>
                  </Colxx>
                </Row>
              </Col>
            </Row>
          </DndContext>
        )}
        <div>
          <Button
            color="light"
            className="top-right-button"
            onClick={() => {
              if (changed && !sawWarning) {
                toast.warn(
                  "You have made some changes. Are you sure to leave the page?"
                );
                setSawWarning(true);
              } else if (sawWarning || !changed) {
                navigate(-1);
              }
            }}
          >
            Cancel
          </Button>
          {/* {!isNew && (
            <Button
              color="primary"
              className="top-right-button ml-2"
              onClick={handleDeleteUser}
            >
              Delete
            </Button>
          )} */}
          {changed && (
            <Button
              color="primary"
              className="top-right-button ml-2"
              onClick={handleSubmit}
              disabled={submitting}
            >
              {isNew ? "Add" : "Update"}
            </Button>
          )}
        </div>
        {addWindowModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div
              className="in-modal-content"
              style={{ top: "20%", height: "60vh" }}
            >
              <AddWindowModal
                toggle={toggleAddWindowModal}
                values={slotWindows}
                setValues={setSlotWindows}
              />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default EditSlotPage;
