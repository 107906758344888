import React, { useState } from "react";
import axios from "axiosClient";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";

import { useToken } from "auth/useToken";
import { toast } from "react-toastify";

const AddCandidateModal = ({ toggle, partnerName, partnerId }) => {
  const [candidate, setCandidate] = useState({
    BookedBy: "anonymous",
    BookingDate: "25/04/2024",
    BookingMethod: `Agent: ${partnerName}`,
    Candidate: "",
    IDNo: "",
    Location: "Edu-Action Exams Centre",
    Module: "Academic - IELTS",
    PartnerId: partnerId,
    SessionDate: "25/05/2024",
    SessionId: "1291214",
    SpeakingTestDate: "",
    SpeakingTestTime: "",
    SpeakingTestVenue: "",
    Status: "Paid",
    Type: "L/R/W",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [token, setToken] = useToken();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCandidate({
      ...candidate,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      if (errorMessage === "") {
        axios
          .post(`/api/partners/new-candidate`, candidate, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((result) => {
            // console.log(result.data);
            setSent(true);
            setSending(false);

            toast.success("Successfully saved");
          })
          .catch((err) => {
            setErrorMessage(err.response?.data);
            toast.error(err.response?.data);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <br />
                      Please, enter candidate details:
                      {/* <>{JSON.stringify(candidates)}</> */}
                      {sending && (
                        <span className="btn btn-warning">Sending...</span>
                      )}
                      {sent && <span className="btn btn-success">DONE!</span>}
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Booked By</Label>
                        <Input
                          type="text"
                          name="BookedBy"
                          value={candidate.BookedBy}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Booking Date</Label>
                        <Input
                          type="text"
                          name="BookingDate"
                          value={candidate.BookingDate}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Booking Method</Label>
                        <Input
                          type="text"
                          name="BookingMethod"
                          value={candidate.BookingMethod}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Candidate</Label>
                        <Input
                          type="text"
                          name="Candidate"
                          value={candidate.Candidate}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>ID No</Label>
                        <Input
                          type="text"
                          name="IDNo"
                          value={candidate.IDNo}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Location</Label>
                        <Input
                          type="text"
                          name="Location"
                          value={candidate.Location}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Module</Label>
                        <Input
                          type="text"
                          name="Module"
                          value={candidate.Module}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Session Date</Label>
                        <Input
                          type="text"
                          name="SessionDate"
                          value={candidate.SessionDate}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Session Id</Label>
                        <Input
                          type="text"
                          name="SessionId"
                          value={candidate.SessionId}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Status</Label>
                        <Input
                          type="text"
                          name="Status"
                          value={candidate.Status}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Type</Label>
                        <Input
                          type="text"
                          name="Type"
                          value={candidate.Type}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <br />

                      {sending && (
                        <span className="btn btn-warning">Sending...</span>
                      )}
                      {sent && <span className="btn btn-success">DONE!</span>}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
      <Row></Row>
      <div>
        <Button
          color="light"
          className="top-right-button"
          onClick={() => toggle()}
        >
          Close
        </Button>
        {!sent && (
          <Button
            color="primary"
            className="ml-2 top-right-button"
            onClick={handleSubmit}
          >
            Save
          </Button>
        )}
      </div>
      &nbsp;
      <div>{errorMessage}</div>
    </div>
  );
};

export default AddCandidateModal;
