import axios from "axios";
// const setUrl = "http://localhost:6005";
const setUrl = "https://api-uz.edu-action.uk/";

const axiosClient = axios.create({
  baseURL: setUrl,
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
);
export default axiosClient;
