import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  CardTitle,
} from "reactstrap";
import Select from "react-select";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import { toast } from "react-toastify";
import ConfirmModal from "components/ConfirmModal";

const EditInvigilator = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      ...values,
    };

    axios
      .post(`/api/invigilator/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.status === 200) {
          navigate("/app/invigilators");
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`/api/invigilator/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.status === 200) {
          navigate("/app/invigilators");
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/invigilator/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let { data } = result;
        setValues(data);
        setLoading(false);
      });
  }, [id]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div>
              <h1>Update Invigilator</h1>
              <div className="pt-0 breadcrumb-container d-sm-block d-lg-inline-block float-r">
                <i
                  className="simple-icon-trash form-actions text-muted"
                  onClick={() => setDeleteModal(true)}
                />
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card className="mb-4">
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label>Full Name</Label>
                            <Input
                              type="text"
                              name="fullName"
                              value={values.fullName}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Rate</Label>
                            <Input
                              type="number"
                              name="rate"
                              value={values.rate}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Telegram ID</Label>
                            <Input
                              type="number"
                              name="telegramId"
                              value={values.telegramId}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Supervisor</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="isSupervisor"
                              value={{
                                label: values.isSupervisor ? "Yes" : "No",
                                value: values.isSupervisor,
                              }}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  isSupervisor: e.value,
                                });
                              }}
                              options={[
                                { label: "Yes", value: true },
                                { label: "No", value: false },
                              ]}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          </>
        )}
        <div className="centered">
          <Button
            color="light"
            className="top-right-button"
            onClick={() => navigate("/app/invigilators")}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
        <ConfirmModal
          modal={deleteModal}
          setModal={setDeleteModal}
          action={handleDelete}
          title={`Are you sure you want to delete ${values.fullName}?`}
        />
      </div>
    </AppLayout>
  );
};

export default EditInvigilator;
