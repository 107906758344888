import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import SignatureCanvas from "react-signature-canvas";
import Select from "react-select";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";

const EditCertificateCandidate = (props) => {
  const { toggle, candidateId } = props;
  const user = useUser();

  const initialValues = {
    COUNTY_STATE: "",
    TOWN_CITY: "",
    ADDRESS_LINE2: "",
    ADDRESS_LINE1: "",
    POSTCODE: "",
  };

  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState({});

  const [regionsOptions, setRegionsOptions] = useState([]);
  const [placesOptions, setPlacesOptions] = useState([]);

  const [isInFullScreen, setIsInFullScreen] = useState(false);

  const populateRegions = () => {
    axios
      .get(`/api/candidate-regions`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        let options = [];
        result.data.forEach((element) => {
          options.push({ label: element.name, value: element.name });
        });
        setRegionsOptions(options);
      });
  };

  const populatePlaces = (region) => {
    axios
      .get(`/api/candidate-places?region=${region}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        // console.log(result.data);
        let placeOptions = [];
        result.data.forEach((element) => {
          placeOptions.push({ label: element.name, value: element.name });
        });
        setPlacesOptions(placeOptions);
      });
  };

  let signPad = {};

  const clear = () => {
    signPad.clear();
  };

  const trim = () => {
    setUpdated(true);
    handleImage(signPad.getTrimmedCanvas().toDataURL("image/png"));
    toast.warning("Don't forget to press the Update button");
    if (isInFullScreen) {
      setIsInFullScreen(false);
    }
  };

  const handleImage = async (url) => {
    setUploadLoading(true);
    try {
      let { data } = await axios.post(
        "/api/upload-image",
        {
          image: url,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //set image in te state
      setUploadedImg(data);
      setUploadLoading(false);
      toast.success("The signature successfully uploaded");
    } catch (err) {
      console.log(err);
      setUploadLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleChange = (e) => {
    setUpdated(true);
    const { name, value } = e.target;

    if (name === "TELEPHONE") {
      setValues({
        ...values,
        [name]: value,
        MOBILE: value,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    if (values.collectedSignature) {
      data = { ...values, updatedBy: user.username };
    } else {
      data = {
        ...values,
        collectedSignature: uploadedImg,
        collectedSigned: Object.keys(uploadedImg).length !== 0,
        updatedBy: user.username,
      };
    }
    axios
      .post(`/api/candidate/${candidateId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        // console.log(result);
        if (result.status === 200) {
          setErrorMessage("");
          setSuccess(true);
          toast.success(
            candidateId ? "Successfully updated" : "Successfully added"
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        // console.log(err.response.data);
        setErrorMessage(Object.values(err.response.data.errors)[0]);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    setLoading(true);
    if (candidateId) {
      axios
        .get(`/api/candidate/${candidateId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setValues(result.data);
          setLoading(false);
        });
    } else {
      setValues(initialValues);
      setLoading(false);
    }
  }, [candidateId]);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>
              {candidateId ? "Update Details" : "Add Candidate"} -{" "}
              {values.CANDIDATE_NUMBER}
            </h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form className="text-left">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label>Candidate No</Label>
                        <Input
                          type="text"
                          name="CANDIDATE_NUMBER"
                          value={values.CANDIDATE_NUMBER}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Name</Label>
                        <Input
                          type="text"
                          name="GIVEN_NAME"
                          value={values.GIVEN_NAME}
                          disabled={candidateId}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Surname</Label>
                        <Input
                          type="text"
                          name="FAMILY_NAME"
                          value={values.FAMILY_NAME}
                          disabled={candidateId}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Passport</Label>
                        <Input
                          type="text"
                          name="CANDIDATE_ID"
                          value={values.CANDIDATE_ID}
                          disabled={candidateId}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Exam Date</Label>
                        <Input
                          type="text"
                          name="TEST_DATE"
                          value={values.TEST_DATE}
                          disabled={candidateId}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>How to get the Certificate?</Label>
                        <Input
                          type="text"
                          name="getCertificateType"
                          value={values.getCertificateType}
                          disabled={candidateId}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    {values.getCertificateType === "From Partner" && (
                      <Col md="6">
                        <FormGroup>
                          <Label>Region </Label>
                          <Input
                            type="text"
                            name="getCertificateRegion"
                            value={values.getCertificateRegion}
                            disabled={candidateId}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    )}

                    {values.getCertificateRegion && (
                      <Col md="6">
                        <FormGroup>
                          <Label>Place </Label>
                          <Input
                            type="text"
                            name="getCertificatePlace"
                            value={values.getCertificatePlace}
                            disabled={candidateId}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    )}

                    <Col md="6">
                      <FormGroup>
                        <Label>Who is collecting the Certificate?</Label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="collectedPerson"
                          value={{
                            label: values.collectedPerson,
                            value: values.collectedPerson,
                          }}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              collectedPerson: e.value,
                            });
                            setUpdated(true);
                          }}
                          options={[
                            { label: "Self", value: "Self" },
                            { label: "Other", value: "Other" },
                          ]}
                        />
                      </FormGroup>
                    </Col>
                    {values.collectedPerson === "Other" && (
                      <>
                        <Col md="6">
                          <FormGroup>
                            <Label>First Name</Label>
                            <Input
                              type="text"
                              name="collectedFirstName"
                              value={values.collectedFirstName}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Last Name</Label>
                            <Input
                              type="text"
                              name="collectedLastName"
                              value={values.collectedLastName}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Passport</Label>
                            <Input
                              type="text"
                              name="collectedPassportNo"
                              value={values.collectedPassportNo}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )}

                    <Col md="12" className="text-left"></Col>
                    <Col md="12" className="text-left">
                      <Separator className="mb-5" />
                      <h4>
                        <strong>Address:</strong> {values.COUNTY_STATE}{" "}
                        {values.TOWN_CITY} {values.ADDRESS_LINE2}{" "}
                        {values.ADDRESS_LINE1}. Post Code: {values.POSTCODE}{" "}
                      </h4>
                    </Col>
                  </Row>
                </Form>
                <div className={isInFullScreen ? "cert-fs" : ""}>
                  <div className="no-wrap">
                    <h2>Signature:</h2>
                    {!(uploadedImg.Location || values.collectedSignature) && (
                      <>
                        <Button
                          color="secondary"
                          className="mr-1"
                          size="xs"
                          onClick={clear}
                        >
                          Clear
                        </Button>
                        <Button
                          color="secondary"
                          size="xs"
                          onClick={trim}
                          disabled={uploadLoading}
                        >
                          Save
                        </Button>
                        <button
                          className="header-icon btn btn-empty d-none d-sm-inline-block"
                          type="button"
                          id="fullScreenButton"
                          onClick={() => setIsInFullScreen(!isInFullScreen)}
                        >
                          {isInFullScreen ? (
                            <i className="simple-icon-size-actual d-block" />
                          ) : (
                            <i className="simple-icon-size-fullscreen d-block" />
                          )}
                        </button>
                      </>
                    )}
                  </div>
                  {!(uploadedImg.Location || values.collectedSignature) && (
                    <SignatureCanvas
                      penColor="green"
                      canvasProps={{
                        width: isInFullScreen ? window.innerWidth - 100 : 500,
                        height: isInFullScreen ? window.innerHeight - 200 : 200,
                        className: "sign-canvas",
                      }}
                      ref={(ref) => {
                        signPad = ref;
                      }}
                    />
                  )}
                  {uploadedImg.Location ? (
                    <img alt="signature" src={uploadedImg.Location} />
                  ) : null}
                  {values.collectedSignature ? (
                    <img
                      alt="signature"
                      src={values.collectedSignature.Location}
                    />
                  ) : null}
                </div>
                <div className="mt-3">
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={toggle}
                  >
                    Close
                  </Button>
                  {updated && (
                    <Button
                      color={success ? "success" : "primary"}
                      className="top-right-button ml-2"
                      onClick={handleSubmit}
                      disabled={success}
                    >
                      {success ? "Done" : `${candidateId ? "Update" : "Add"}`}
                    </Button>
                  )}
                </div>
                <div>{errorMessage}</div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default EditCertificateCandidate;
