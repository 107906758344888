import React, { useEffect, useState } from "react";
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import Draggable from "components/DndComponents/Draggable";
import Droppable from "components/DndComponents/Droppable";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Button,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Input,
  CardFooter,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { CKEditor } from "ckeditor4-react";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";
import moment from "moment";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import AddWindowModal from "./addWindowModal";
import { dateDropToSlash, dateSlashToDrop } from "utils/stringFunctions";
import AddInvModal from "./AddInvModal";

const initialValues = {
  name: "",
  status: true,
  slots: [],
};

const InvigilatorsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const [values, setValues] = useState(initialValues);
  const [invigilators, setInvigilators] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [selectedInvigilators, setSelectedInvigilators] = useState([]);
  const [selectedSupervisors, setSelectedSupervisors] = useState([]);
  const [slotWindows, setSlotWindows] = useState([]);
  const [slotRooms, setSlotRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState({});
  const [addWindowModal, setAddWindowModal] = useState(false);
  const [changed, setChanged] = useState(false);
  const [sawWarning, setSawWarning] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [botLoading, setBotLoading] = useState(false);
  const [unselectedReason, setUnselectedReason] = useState(
    "You can take your chance next time. Good luck!"
  );
  const [reasonField, setReasonField] = useState(false);
  const [removingInvs, setRemovingInvs] = useState(false);
  const [addInvModal, setAddInvModal] = useState(false);

  const [body, setBody] = useState("");

  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleAddWindow = () => {
    toggleAddWindowModal();
  };

  const toggleAddWindowModal = () => {
    setAddWindowModal(!addWindowModal);
  };

  const toggleAddInvModal = () => {
    setAddInvModal(!addInvModal);
  };

  // State to track selected supervisors and invigilators
  const [selectedToRemove, setSelectedToRemove] = useState([]);

  // Function to handle remove checkbox change
  const handleRemoveCheckboxChange = (invigilatorId) => {
    setSelectedToRemove((prevSelected) =>
      prevSelected.includes(invigilatorId)
        ? prevSelected.filter((id) => id !== invigilatorId)
        : [...prevSelected, invigilatorId]
    );
  };

  const handleDragEnd = (e) => {
    const { active, over } = e;
    let newSelectedRoom = { ...selectedRoom };
    console.log(active, over, newSelectedRoom);
    if (over?.id && active?.id) {
      const activeId = active.id.split("-")[1];
      if (active.id.split("-")[0] === "sup") {
        if (!newSelectedRoom.supervisors[over.id.split("-")[1]].available) {
          toast.warn("This slot is not empty.");
        } else {
          newSelectedRoom.supervisors[over.id.split("-")[1]] = {
            ...supervisors[activeId],
            available: false,
          };
          let tempSupervisors = [...supervisors];
          setSelectedRoom(newSelectedRoom);
          tempSupervisors.splice(activeId, 1);
          setSupervisors(tempSupervisors);
          setChanged(true);
          console.log("tempSupervisors", tempSupervisors);
          console.log("newSelectedRoom", newSelectedRoom);
        }
      } else {
        if (!newSelectedRoom.invigilators[over.id].available) {
          toast.warn("This slot is not empty.");
        } else {
          newSelectedRoom.invigilators[over.id] = {
            ...invigilators[activeId],
            available: false,
          };
          let tempInvigilators = [...invigilators];
          setSelectedRoom(newSelectedRoom);
          tempInvigilators.splice(activeId, 1);
          setInvigilators(tempInvigilators);
          setChanged(true);
        }
      }
    }
  };

  const handleRemoveInvigilator = (index) => {
    if (selectedRoom.invigilators[index]._id) {
      let newInvigilators = [...invigilators];
      newInvigilators.unshift({
        ...selectedRoom.invigilators[index],
        changed: true,
      });
      setInvigilators(newInvigilators);
      let tempRoom = { ...selectedRoom };
      tempRoom.invigilators[index] = { available: true };
      setSelectedRoom(tempRoom);
      setChanged(true);
    }
  };

  const handleRemoveSupervisor = (index) => {
    if (selectedRoom.supervisors[index]._id) {
      let newSupervisors = [...supervisors];
      newSupervisors.unshift({
        ...selectedRoom.supervisors[index],
        changed: true,
      });
      setSupervisors(newSupervisors);
      let tempRoom = { ...selectedRoom };
      tempRoom.supervisors[index] = { available: true };
      setSelectedRoom(tempRoom);
      setChanged(true);
    }
  };

  const handleGenerate = () => {
    setGenerating(true);
    axios
      .post(
        `/api/generate-rooms`,
        {
          mainTestDate: values.mainTestDate,
          venue: values.venue,
          region: values.region,
          mainTestDateId: values._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        setGenerating(false);
        toast.success("Successfully generated");
        navigate(0);
      })
      .catch((err) => {
        toast.error("Something went wrong", err.response.data);
        setGenerating(false);
      });
  };

  const handleUpdate = () => {
    navigate(0);
  };

  const handleSelectRoom = (room) => {
    const newArray = [];

    for (let i = 0; i < room.room.invigilatorCells.length; i++) {
      if (room?.invigilators[i]) {
        newArray.push({ ...room.invigilators[i], available: false });
      } else {
        newArray.push({ available: true });
      }
    }

    const supArray = [];

    for (let i = 0; i < room.room.supervisorCells.length; i++) {
      if (room?.supervisors[i]) {
        supArray.push({ ...room.supervisors[i], available: false });
      } else {
        supArray.push({ available: true });
      }
    }
    setSelectedRoom({ ...room, invigilators: newArray, supervisors: supArray });
  };

  const handleSaveRoom = (id, invigilators, supervisors) => {
    let postInvigilators = invigilators.map((invigilator) => invigilator._id);
    let postSupervisors = supervisors.map((supervisor) => supervisor._id);
    axios
      .post(
        `/api/room-invigilators`,
        {
          id,
          invigilators: postInvigilators,
          supervisors: postSupervisors,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        toast.success("Successfully saved");
        navigate(0);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const handleGenrateExcelRoom = () => {
    setDownloadLoading(true);
    axios
      .post(
        `/api/generate-excel`,
        {
          id: selectedRoom._id,
          mainTestDate: values.mainTestDate,
          venue: values.venue,
          region: values.region,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "arraybuffer",
        }
      )
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `Seating-Plan_${moment(
          values.mainTestDate,
          "DD/MM/YYYY"
        ).format("DD-MM-YYYY")}_${selectedRoom.room.name}.xlsx`; //or any other extension
        document.body.appendChild(link);
        link.click();
        toast.success("The file is downloaded successfully.");
        setDownloadLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const handleGenrateAttendance = () => {
    setDownloadLoading(true);
    axios
      .post(
        `/api/generate-attendance`,
        {
          id: selectedRoom._id,
          mainTestDate: values.mainTestDate,
          venue: values.venue,
          region: values.region,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "arraybuffer",
        }
      )
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `Attendance_${moment(
          values.mainTestDate,
          "DD/MM/YYYY"
        ).format("DD-MM-YYYY")}_${selectedRoom.room.name}.pdf`; //or any other extension
        document.body.appendChild(link);
        link.click();
        toast.success("The file is downloaded successfully.");
        setDownloadLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const handleGenrateDeskLabel = () => {
    setDownloadLoading(true);
    axios
      .post(
        `/api/generate-desk-label`,
        {
          id: selectedRoom._id,
          mainTestDate: values.mainTestDate,
          venue: values.venue,
          region: values.region,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "arraybuffer",
        }
      )
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `Desk_Label_${moment(
          values.mainTestDate,
          "DD/MM/YYYY"
        ).format("DD-MM-YYYY")}_${selectedRoom.room.name}.pdf`; //or any other extension
        document.body.appendChild(link);
        link.click();
        toast.success("The file is downloaded successfully.");
        setDownloadLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const handleSendMainNotification = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let data = {
      dateId: id,
      text: body,
    };
    // console.log(data);
    axios
      .post(`/api/invigilators/main-request`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setSubmitting(false);
        toast.success("Successfully sent the notificaion to the group.");
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  const handleInformSelectedInvs = (e) => {
    e.preventDefault();
    setBotLoading(true);

    let arr = [];
    selectedRoom.invigilators.forEach((invigilator) => {
      if (invigilator.telegramId) {
        arr.push({
          telegramId: invigilator.telegramId,
          text: `Dear ${invigilator.fullName}

You have been assigned to ${selectedRoom.room?.venueName} - ${
            selectedRoom.room?.name
          } on ${moment(values.mainTestDate, "DD/MM/YYYY").format(
            "MMMM Do YYYY"
          )}.
        
You can also check your name in the list sent in the group.
        
Please be  at  ${selectedRoom.room?.startTime}⏰ 
DO NOT BE LATE
        
Regards,
Edu Action Exams Centre`,
        });
      }
    });

    selectedRoom.supervisors.forEach((supervisor) => {
      if (supervisor.telegramId) {
        arr.push({
          telegramId: supervisor.telegramId,
          text: `Dear ${supervisor.fullName}

You have been assigned to ${selectedRoom.room?.venueName} - ${
            selectedRoom.room?.name
          } on ${moment(values.mainTestDate, "DD/MM/YYYY").format(
            "MMMM Do YYYY"
          )}.
        
You can also check your name in the list sent in the group.
        
Please be at ${selectedRoom.room?.startTime}⏰ 
DO NOT BE LATE
        
Regards,
Edu Action Exams Centre`,
        });
      }
    });

    let data = {
      arr,
    };
    axios
      .post(`/api/inform-invigilators`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setSubmitting(false);
        toast.success(
          "Successfully sent the notificaions to the invigilators."
        );
      })
      .catch((err) => {
        toast.error(err.response.data);
      })
      .finally(() => {
        setBotLoading(false);
      });
  };

  const handleInformGroup = (e) => {
    e.preventDefault();
    setBotLoading(true);

    let text = `Dear All,

Please check your name in the list for the ${moment(
      values.mainTestDate,
      "DD/MM/YYYY"
    ).format("MMMM Do YYYY")}.
    
VENUE: ${selectedRoom.room?.venueName} - ${selectedRoom.room?.name}
${
  selectedRoom.room?.locationLink
    ? `LOCATION: ${selectedRoom.room?.locationLink}`
    : ""
}  
`;

    selectedRoom.supervisors.forEach((supervisor) => {
      if (supervisor.telegramId) {
        text += `
${supervisor.fullName}`;
      }
    });
    selectedRoom.invigilators.forEach((invigilator) => {
      if (invigilator.telegramId) {
        text += `
${invigilator.fullName}`;
      }
    });

    text += `
    

Please be  at  ${selectedRoom.room?.startTime}⏰ 
DO NOT BE LATE`;

    let data = {
      text,
    };
    console.log(text);
    axios
      .post(`/api/inform-in-group`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setSubmitting(false);
        toast.success("Successfully sent the notification to the group.");
      })
      .catch((err) => {
        toast.error(err.response.data);
      })
      .finally(() => {
        setBotLoading(false);
      });
  };

  const handleInformNotSelectedInvs = () => {
    // e.preventDefault();
    setBotLoading(true);

    let arr = [];
    invigilators.forEach((invigilator) => {
      if (
        !selectedInvigilators.includes(invigilator._id) &&
        invigilator.telegramId
      ) {
        arr.push({
          telegramId: invigilator.telegramId,
          text: `Dear ${invigilator.fullName}

You have not been assigned to the test on ${moment(
            values.mainTestDate,
            "DD/MM/YYYY"
          ).format("MMMM Do YYYY")}.
        
${unselectedReason}
                
Regards,
Edu Action Exams Centre`,
        });
      }
    });

    supervisors.forEach((supervisor) => {
      if (
        !selectedSupervisors.includes(supervisor._id) &&
        supervisor.telegramId
      ) {
        arr.push({
          telegramId: supervisor.telegramId,
          text: `Dear ${supervisor.fullName}

You have not been assigned to the test on ${moment(
            values.mainTestDate,
            "DD/MM/YYYY"
          ).format("MMMM Do YYYY")}.
        
${unselectedReason}
                
Regards,
Edu Action Exams Centre`,
        });
      }
    });

    let data = {
      arr,
    };
    // console.log(data);
    axios
      .post(`/api/inform-invigilators`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setSubmitting(false);
        toast.success(
          "Successfully sent the notifications to the invigilators."
        );
      })
      .catch((err) => {
        toast.error(err.response.data);
      })
      .finally(() => {
        setBotLoading(false);
        setReasonField(false);
      });
  };

  const handleRemoveInvsFromDate = async () => {
    console.log(selectedToRemove);
    try {
      let result = await axios.post(
        `/api/remove-invigilators-from-date`,
        { documentId: id, invigilatorIds: selectedToRemove },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (result.status === 200) {
        toast.success("Successfully removed invigilators from date");
        navigate(0);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  useEffect(() => {
    !isNew && setLoading(true);
    if (!isNew) {
      axios
        .get(`/api/speaking/date/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result;
          setValues(data);
          setInvigilators(data.invigilators.filter((one) => !one.isSupervisor));
          setSupervisors(data.invigilators.filter((one) => one.isSupervisor));
          setBody(`<b>Dear All!</b>
       
Please confirm if you are available for the exam on ${moment(
            data.mainTestDate,
            "DD/MM/YYYY"
          ).format("MMMM Do YYYY")}.

          
P.S. If you are not available, please ignore this message.
P.S.S. If you are available, please click on the button below and follow the instructions (if you're asked) to confirm your availability.

<i>Thanks in advance</i>`);
          setLoading(false);
        });
      axios
        .get(`/api/room-invigilators?mainTestDateId=${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          setSlotRooms(result.data.rooms);
          setSelectedInvigilators(result.data.invigilators);
          setSelectedSupervisors(result.data.supervisors);
        });
    }
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{isNew ? "New" : "Update"} Exam Invigilators</h1>
              <div
                className="text-zero top-right-button-container"
                style={{ display: "flex" }}
              >
                <div>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      color="primary"
                      // className="btn-xs"
                      outline
                    >
                      Actions
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        disabled={generating}
                        onClick={() => handleGenerate()}
                      >
                        {generating ? "Loading..." : "Generate Rooms"}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card className="mb-4 text-left">
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <>
                          <h2>Message Content</h2>

                          <Input
                            value={body}
                            onChange={(event) => setBody(event.target.value)}
                            type="textarea"
                            style={{ height: "200px" }}
                          />
                        </>
                        <Button
                          color="primary"
                          className="top-right-button mt-2"
                          size="sm"
                          onClick={handleSendMainNotification}
                        >
                          Send Request Notification
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Separator className="mt-2" />
              </Colxx>
              <Col md="6">
                <Row className="mt-3">
                  <h2 className="ml-4">
                    Rooms
                    {slotRooms.length === 0 && (
                      <Button
                        color="primary"
                        className="top-right-button ml-2"
                        size="sm"
                        onClick={handleUpdate}
                      >
                        Update
                      </Button>
                    )}
                    {slotRooms.map((one, index) => (
                      <Button
                        color={
                          selectedRoom.room?._id === one.room._id
                            ? "primary"
                            : "light"
                        }
                        className="top-right-button ml-2"
                        size="sm"
                        onClick={() => handleSelectRoom(one)}
                      >
                        {one.room.name}
                      </Button>
                    ))}
                  </h2>
                  <Colxx xxs="12">
                    {selectedRoom.room && (
                      <Card className="mb-4">
                        <CardBody>
                          <p>{selectedRoom.room?.name}</p>

                          <p>Supervisors</p>
                          <div className="text-left">
                            {selectedRoom.supervisors?.map(
                              (supervisor, index) => (
                                <span
                                  style={{
                                    overflow: "hidden",
                                    clear: "both",
                                    marginBottom: "2px",
                                  }}
                                >
                                  <span>
                                    <Droppable
                                      droppedItem={
                                        supervisor.fullName
                                          ? `${index + 1}. ${
                                              supervisor.fullName
                                            }`
                                          : `${index + 1}. drop here`
                                      }
                                      uniqueId={`sup-${index}`}
                                    />
                                    <i
                                      className="iconsminds-remove ml-1 mr-2 clickable"
                                      onClick={() =>
                                        handleRemoveSupervisor(index)
                                      }
                                    />
                                  </span>
                                </span>
                              )
                            )}
                          </div>
                          <Separator className="mt-2 mb-2" />
                          <p>Invigilators</p>
                          <div className="text-left">
                            {selectedRoom.invigilators?.map(
                              (invigilator, index) => (
                                <span
                                  style={{
                                    overflow: "hidden",
                                    clear: "both",
                                    marginBottom: "2px",
                                  }}
                                >
                                  <Droppable
                                    droppedItem={
                                      invigilator.fullName
                                        ? `${index + 1}. ${
                                            invigilator.fullName
                                          }`
                                        : `${index + 1}. drop here`
                                    }
                                    uniqueId={`${index}`}
                                  />
                                  <i
                                    className="iconsminds-remove ml-2 clickable"
                                    onClick={() =>
                                      handleRemoveInvigilator(index)
                                    }
                                  />
                                </span>
                              )
                            )}
                          </div>
                        </CardBody>
                        <CardFooter>
                          <div className="d-flex">
                            <Button
                              color="light"
                              size="xs"
                              className="mr-2"
                              onClick={() =>
                                handleSaveRoom(
                                  selectedRoom._id,
                                  selectedRoom.invigilators,
                                  selectedRoom.supervisors
                                )
                              }
                            >
                              Save
                            </Button>

                            <UncontrolledDropdown>
                              <DropdownToggle
                                caret
                                // className="btn-xs"
                                color="light"
                                size="xs"
                                className="mr-2"
                                // outline
                                disabled={downloadLoading}
                              >
                                Actions
                              </DropdownToggle>
                              <DropdownMenu end>
                                <DropdownItem onClick={handleGenrateExcelRoom}>
                                  Generate Excel
                                </DropdownItem>
                                <DropdownItem onClick={handleGenrateAttendance}>
                                  Generate Attendance
                                </DropdownItem>
                                <DropdownItem onClick={handleGenrateDeskLabel}>
                                  Generate Desk Labels
                                </DropdownItem>
                                <DropdownItem
                                  onClick={handleInformSelectedInvs}
                                  disabled={botLoading}
                                >
                                  Inform Invigilators
                                </DropdownItem>
                                <DropdownItem
                                  onClick={handleInformGroup}
                                  disabled={botLoading}
                                >
                                  Inform in the Group
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </CardFooter>
                      </Card>
                    )}
                  </Colxx>
                </Row>
              </Col>
              <Col md="6">
                <Row className="mt-3 ml-2">
                  <Colxx xxs="12">
                    <Row>
                      <h2 className="ml-4 mr-2">
                        Invigilators{" "}
                        {/* <span>
                    <i
                      className="simple-icon-plus ml-2 clickable"
                      onClick={() => handleClickSlot()}
                    />
                  </span> */}
                      </h2>

                      <Button
                        color="light"
                        size="xs"
                        className="mb-2"
                        onClick={() =>
                          reasonField
                            ? handleInformNotSelectedInvs()
                            : setReasonField(!reasonField)
                        }
                        disabled={botLoading}
                      >
                        Inform Unselected
                      </Button>
                      <Button
                        color={removingInvs ? "primary" : "light"}
                        size="xs"
                        className="ml-2 mb-2"
                        onClick={() => setRemovingInvs(!removingInvs)}
                      >
                        Remove Invigilators
                      </Button>
                      <Button
                        color="light"
                        size="xs"
                        className="ml-2 mb-2"
                        onClick={toggleAddInvModal}
                      >
                        Add Invigilators
                      </Button>
                    </Row>
                  </Colxx>
                  <Colxx xxs="12">
                    {reasonField && (
                      <Input
                        type="textarea"
                        name="Reason"
                        value={unselectedReason}
                        onChange={(e) => setUnselectedReason(e.target.value)}
                        className="mb-3"
                      />
                    )}
                  </Colxx>
                  {/* <span className="search-sm d-inline-block mb-1 align-top">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder={"Search"}
                      onKeyPress={(e) => onSearchKey(e)}
                    />
                  </span> */}
                  <Colxx xxs="12">
                    <Card>
                      {removingInvs ? (
                        <CardBody>
                          <div>
                            <p>Supervisors</p>
                            {supervisors.length === 0 && "No data"}
                            {supervisors.map((supervisor, index) => (
                              <Row key={index}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedToRemove.includes(
                                      supervisor._id
                                    )}
                                    onChange={() =>
                                      handleRemoveCheckboxChange(supervisor._id)
                                    }
                                  />{" "}
                                  <span className={`list-item-heading`}>
                                    {supervisor.fullName}
                                  </span>
                                </label>
                              </Row>
                            ))}
                          </div>

                          <Separator className="mt-2 mb-2" />

                          <div>
                            <p>Invigilators</p>
                            {invigilators.length === 0 && "No data"}
                            {invigilators.map((invigilator, index) => (
                              <Row key={index}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedToRemove.includes(
                                      invigilator._id
                                    )}
                                    onChange={() =>
                                      handleRemoveCheckboxChange(
                                        invigilator._id
                                      )
                                    }
                                  />{" "}
                                  <span className={`list-item-heading`}>
                                    {invigilator.fullName}
                                  </span>
                                </label>
                              </Row>
                            ))}
                          </div>
                          <Button onClick={handleRemoveInvsFromDate}>
                            Remove
                          </Button>
                        </CardBody>
                      ) : (
                        <CardBody>
                          <p>Supervisors</p>
                          {supervisors.length === 0 && "No data"}
                          {supervisors.map((supervisor, index) => (
                            <Row key={index}>
                              {!selectedSupervisors.includes(
                                supervisor._id
                              ) && (
                                <span
                                  className={`list-item-heading ${
                                    supervisor.changed && "changed-candidate"
                                  }`}
                                >
                                  <Draggable
                                    name={`${supervisor.fullName}`}
                                    uniqueId={`sup-${index}`}
                                  />
                                </span>
                              )}
                            </Row>
                          ))}
                          <Separator className="mt-2 mb-2" />
                          <p>Invigilators</p>
                          {invigilators.length === 0 && "No data"}
                          {invigilators.map((invigilator, index) => (
                            <Row key={index}>
                              {!selectedInvigilators.includes(
                                invigilator._id
                              ) && (
                                <span
                                  className={`list-item-heading ${
                                    invigilator.changed && "changed-candidate"
                                  }`}
                                >
                                  <Draggable
                                    name={`${invigilator.fullName}`}
                                    uniqueId={`item-${index}`}
                                  />
                                </span>
                              )}
                            </Row>
                          ))}
                        </CardBody>
                      )}
                    </Card>
                  </Colxx>
                </Row>
              </Col>
            </Row>
          </DndContext>
        )}
        <div>
          <Button
            color="light"
            className="top-right-button"
            onClick={() => {
              if (changed && !sawWarning) {
                toast.warn(
                  "You have made some changes. Are you sure to leave the page?"
                );
                setSawWarning(true);
              } else if (sawWarning || !changed) {
                navigate(-1);
              }
            }}
          >
            Cancel
          </Button>
          {/* {!isNew && (
            <Button
              color="primary"
              className="top-right-button ml-2"
              onClick={handleDeleteUser}
            >
              Delete
            </Button>
          )} */}
        </div>
        {addWindowModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div
              className="in-modal-content"
              style={{ top: "20%", height: "60vh" }}
            >
              <AddWindowModal
                toggle={toggleAddWindowModal}
                values={slotWindows}
                setValues={setSlotWindows}
              />
            </div>
          </div>
        )}
        {addInvModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div
              className="in-modal-content"
              style={{ top: "20%", height: "60vh" }}
            >
              <AddInvModal toggle={toggleAddInvModal} dateId={id} />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default InvigilatorsPage;
