import React from "react";
import { Row, Card, CardBody, Button } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

const ConfirmModal = (props) => {
  const { toggle, action } = props;

  const handleSubmit = async (e) => {
    e.preventDefault();
    await action();
    toggle();
  };

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>Are you sure?</h1>
          </div>
        </Colxx>
      </Row>

      <Row>
        <Colxx xxs="12" className="mb-4">
          <div className="mt-3">
            <Button
              color="primary"
              className="top-right-button"
              onClick={toggle}
            >
              No
            </Button>

            <Button
              color="primary"
              className="top-right-button ml-2"
              onClick={handleSubmit}
            >
              Yes
            </Button>
          </div>
        </Colxx>
      </Row>
    </div>
  );
};

export default ConfirmModal;
