import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import data from "constants/menu";
import Papa from "papaparse";
import { toast } from "react-toastify";
import moment from "moment";

const SaveBulkCandidates = ({
  toggle,
  region = "",
  venue = "",
  dateId = "",
  sessionDate = "",
}) => {
  const navigate = useNavigate();

  const [candidates, setCandidates] = useState([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [file, setFile] = useState(null);
  const [token, setToken] = useToken();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      if (errorMessage === "") {
        axios
          .post(
            `/api/save-candidates`,
            { candidates, region, venue },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            setSent(true);
            setSending(false);
            toast.success("Successfully saved");
          })
          .catch((err) => {
            setErrorMessage(err.response.data);
            toast.error(err.response.data);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form className="text-left">
                  <Row>
                    <Col md="12">
                      <p>
                        Region: {region} &nbsp;&nbsp; | &nbsp;&nbsp; Venue:{" "}
                        {venue}
                      </p>
                      Please, upload the .CSV file of candidates:
                      <FormGroup row>
                        <Colxx sm="9">
                          <Input
                            type="file"
                            accept=".csv"
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files) {
                                setCandidatesLoading(true);
                                Papa.parse(files[0], {
                                  delimiter: ",",
                                  header: true,
                                  transformHeader: function (h) {
                                    return h.replace(/[#\s/()]/g, "");
                                  },
                                  skipEmptyLines: true,
                                  complete: function (results) {
                                    // console.log("Finished:");
                                    // console.log(results.data);
                                    if (results.data[0].UniqueTestTakerID) {
                                      let cands = [];
                                      results.data.forEach((res) => {
                                        cands.push({
                                          CANDIDATE_NUMBER: res.CandidateNo,
                                          CENTRE_ID: res.TestCentreNo,
                                          TEST_FORMAT: res.Delivery,
                                          GIVEN_NAME: res.FirstName,
                                          FAMILY_NAME: res.LastName,
                                          TITLE: res.Title,
                                          TELEPHONE: res.Mobile.replace(
                                            /\D/g,
                                            ""
                                          ),
                                          MOBILE: res.Mobile.replace(/\D/g, ""),
                                          EMAILID: res.Email,
                                          BIRTH_DATE: moment(res.DOB).format(
                                            "DD/MM/YYYY"
                                          ),
                                          GENDER: res.Gender,
                                          IDENTITY: res.IDType,
                                          CANDIDATE_ID: res.IDDoc,
                                          REGISTRATION_DATE: moment(
                                            res.BookingDate
                                          ).format("DD/MM/YYYY"),
                                          CONFIRMATION_DATE: moment(
                                            res.PaymentDate
                                          ).format("DD/MM/YYYY"),
                                          UID: res.UniqueTestTakerID,
                                          TEST_DATE: sessionDate,
                                          MODULE_NAME: res.Variant,
                                          SESSION_DATE: sessionDate,
                                          DATE_OF_BIRTH: moment(res.DOB).format(
                                            "DD/MM/YYYY"
                                          ),
                                          MODULE_TYPE: res.Variant,
                                          COMPOSITE_CANDIDATE_NO:
                                            res.CompositeCandidateNo,
                                          COUNTRY: res.CountryCandidate,
                                          testDateId: dateId,
                                        });
                                      });
                                      // console.log(cands);
                                      setCandidates(cands);
                                    } else {
                                      setCandidates(results.data);
                                    }
                                    setCandidatesLoading(false);
                                  },
                                });
                              }
                            }}
                          />
                        </Colxx>
                      </FormGroup>
                      {/* <>{JSON.stringify(candidates)}</> */}
                      {candidatesLoading ? (
                        <p>Loading...</p>
                      ) : (
                        <p>The number of candidates: {candidates.length}</p>
                      )}
                      {sending && (
                        <span className="btn btn-warning">Uploading...</span>
                      )}
                      {sent && <span className="btn btn-success">DONE!</span>}
                    </Col>
                  </Row>
                </Form>
                <div className="mt-3">
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={() => toggle()}
                  >
                    Close
                  </Button>
                  {!sent && (
                    <Button
                      color="primary"
                      className="ml-2 top-right-button"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  )}
                </div>
                &nbsp;
                <div>{errorMessage}</div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default SaveBulkCandidates;
