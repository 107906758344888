import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { CKEditor } from "ckeditor4-react";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";
import Select from "react-select";

const AddMainVenueModal = (props) => {
  const { toggle, sessionDate, region, venue } = props;
  const user = useUser();

  const initialFields = {
    from: "0",
    to: "0",
    mainTestVenue: "Novza",
    mainTestAddress: "",
    mainTestLocation: "",
    mainTestRoom: "",
  };

  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);
  const [info, setInfo] = useState("");
  const [arr, setArr] = useState([initialFields]);
  const [rooms, setRooms] = useState([]);
  const [roomsforSelect, setRoomsForSelect] = useState([]);

  const handleAddNextArr = () => {
    setArr([...arr, initialFields]);
  };

  const handleRemoveArr = (index) => {
    let tempArrs = [...arr];
    tempArrs.splice(index, 1);
    setArr(tempArrs);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let tempArrs = [...arr];
    tempArrs[index][name] = value;
    setArr(tempArrs);
  };

  const handleSelect = (e, index) => {
    let tempRoom = rooms.find((item) => item._id === e.value);
    let tempArrs = [...arr];
    tempArrs[index]["mainTestRoom"] = tempRoom.name;
    tempArrs[index]["mainTestVenue"] = tempRoom.venueName;
    tempArrs[index]["mainTestAddress"] = tempRoom.address;
    tempArrs[index]["mainTestLocation"] = tempRoom.locationLink;
    tempArrs[index]["roomId"] = tempRoom._id;
    tempArrs[index]["capacity"] = tempRoom.candidateCells.length;
    setArr(tempArrs);
  };

  const handleSubmit = () => {
    // console.log("arr", arr);
    setSending(true);
    axios
      .post(
        `/api/speaking/update-main-test`,
        { mainTestDate: sessionDate, venue, region, details: arr },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setSending(false);
          toast.success("Updated successfully");
          toggle();
          setSuccess(true);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    axios
      .post(
        `/api/speaking/get-first-last`,
        { mainTestDate: sessionDate, venue, region },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setInfo(result?.data);
      });
    axios
      .get(`/api/rooms`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setRooms(result?.data);
        let temp = [];
        result?.data?.map((item) => {
          temp.push({ label: item.name, value: item._id });
        });
        setRoomsForSelect(temp);
      });
  }, []);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>Set Main Venue Details</h1>
          </div>
          <p>
            Region: {region} &nbsp;&nbsp; | &nbsp;&nbsp; Venue: {venue}{" "}
            &nbsp;&nbsp; | &nbsp;&nbsp; First Candidate: {info.first}{" "}
            &nbsp;&nbsp; | &nbsp;&nbsp; Last Candidate: {info.last}
          </p>
          <Separator className="mb-5" />
        </Colxx>
      </Row>

      <Row>
        <Colxx xxs="12" className="mb-4">
          {arr.map((item, index) => (
            <Card key={index} className="mb-4 text-left">
              <div
                className="position-absolute card-top-buttons"
                style={{ zIndex: 100 }}
              >
                {arr.length > 1 && (
                  <span
                    className="btn btn-header-light icon-button"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleRemoveArr(index)}
                  >
                    <i className="simple-icon-trash" />
                  </span>
                )}
                <span
                  className="btn btn-header-light icon-button"
                  style={{ cursor: "pointer" }}
                  onClick={handleAddNextArr}
                >
                  <i className="simple-icon-plus" />
                </span>
              </div>

              <CardBody>
                <Form>
                  <Row>
                    <Col md="2">
                      <FormGroup>
                        <Label>Candiate No - From</Label>
                        <Input
                          name="from"
                          type="text"
                          value={item.from}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <Label>Candiate No - To</Label>
                        <Input
                          name="to"
                          type="text"
                          value={item.to}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <Label>Room Select</Label>
                        <Select
                          name="roomId"
                          options={roomsforSelect}
                          onChange={(e) => handleSelect(e, index)}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label>Venue</Label>
                        <Input
                          name="mainTestVenue"
                          type="text"
                          value={item.mainTestVenue}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="2">
                      <FormGroup>
                        <Label>Room</Label>
                        <Input
                          name="mainTestRoom"
                          type="text"
                          value={item.mainTestRoom}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label>Address</Label>
                        <Input
                          name="mainTestAddress"
                          type="text"
                          value={item.mainTestAddress}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label>Location Link</Label>
                        <Input
                          name="mainTestLocation"
                          type="text"
                          value={item.mainTestLocation}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      md="4"
                      className={
                        item.capacity < Number(item.to) - Number(item.from) + 1
                          ? "danger"
                          : "green"
                      }
                    >
                      <p>
                        Room capacity: {item.capacity}
                        <br />
                        Number of candidates:{" "}
                        {Number(item.to) - Number(item.from) === 0
                          ? "0"
                          : Number(item.to) - Number(item.from) + 1}
                      </p>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          ))}
        </Colxx>
      </Row>
      <div>
        <Button color="light" className="top-right-button" onClick={toggle}>
          Close
        </Button>
        <Button
          color={success ? "success" : "primary"}
          className="top-right-button ml-2"
          onClick={handleSubmit}
          disabled={success || sending}
        >
          {success ? "Done" : `Submit`}
        </Button>
      </div>
    </div>
  );
};

export default AddMainVenueModal;
