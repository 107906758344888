import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Card,
  FormGroup,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import { useToken } from "auth/useToken";
import axios from "axiosClient";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { toast } from "react-toastify";
import moment from "moment";
import { downloadBulk } from "utils/downloadBlobdata";
import EORCandidate from "./eorModal";

const CandidatesListEOR = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [word, setWord] = useState("");
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [token, setToken] = useToken();

  const [importModal, setImportModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [eorModal, setEorModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [candidateId, setCandidateId] = useState(null);
  const [examColumn, setExamColumn] = useState(null);
  const [examColumns, setExamColumns] = useState(["Date"]);
  const [venueColumn, setVenueColumn] = useState(null);
  const [venueColumns, setVenueColumns] = useState(["Date"]);
  const [examDateSelect, setExamDateSelect] = useState(null);

  const navigate = useNavigate();

  const getListWithDate = (date) => {
    setExamColumn(date);
    setVenueColumn(null);
    let filterPostData = word
      ? { search: word, page, filter: { examDate: date } }
      : { page, filter: { examDate: date } };
    axios
      .post(`/api/candidates`, filterPostData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setData(result.data.candidates);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);
        setVenueColumns(result.data.venues.sort());
        setLoading(false);
      });
  };

  const getListWithVenue = (venue) => {
    setVenueColumn(venue);
    let filterPostData = word
      ? { search: word, page, filter: { examDate: examColumn, venue } }
      : { page, filter: { examDate: examColumn, venue } };
    axios
      .post(`/api/candidates`, filterPostData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setData(result.data.candidates);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);
        setLoading(false);
      });
  };

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (word) => {
    setWord(word);
    let filterData = venueColumn
      ? { filter: { examDate: examColumn, venue: venueColumn } }
      : {};
    ///
    axios
      .post(
        `/api/candidates`,
        { search: word, page, ...filterData },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData(result.data.candidates);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    let filterData = venueColumn
      ? { filter: { examDate: examColumn, venue: venueColumn } }
      : {};
    let postData = word
      ? { search: word, page: page + 1, ...filterData }
      : { page: page + 1, ...filterData };
    axios
      .post(`/api/candidates`, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setData([...data, ...result.data.candidates]);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(page + 1);
        setLoading(false);
      });
  };

  const toggleEorModal = () => {
    setEorModal(!eorModal);
  };

  const toggleAddModal = () => {
    setAddModal(!addModal);
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const handleClickCandidate = (id) => {
    setCandidateId(id);
    toggleEditModal();
  };
  const handleClickEor = (id) => {
    setCandidateId(id);
    toggleEorModal();
  };

  if (importModal || editModal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const handleDownload = () => {
    setDownloadLoading(true);
    let filterData = venueColumn
      ? { filter: { examDate: examColumn, venue: venueColumn } }
      : examColumn
      ? { filter: { examDate: examColumn } }
      : {};
    let postData = word ? { search: word, ...filterData } : { ...filterData };
    // console.log("post data", postData);
    axios
      .post("/api/download-candidates", postData, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log("result ready");
        // FileDownload(result.data, "enquiries.xlsx");
        // window.saveAs(new Blob([result.data]), "enquiries.xlsx");
        downloadBulk(result, "IELTS_Candidates.xlsx");

        toast.success("The file is downloaded successfully.");
        setDownloadLoading(false);
      });
  };

  useEffect(() => {
    axios
      .post(
        `/api/candidates`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData(result.data.candidates);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);
        setLoading(false);
        setExamColumns(
          result.data.examDates
            .map((one) => moment(one, "DD-MM-YYYY").format("YYYY-MM-DD"))
            .sort()
        );
        setVenueColumns(result.data.venues.sort());
      });
  }, [importModal]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Candidates List</h1>
            </div>
            <div className="mb-2 justify-content-between">
              <div className="d-block mb-2 d-md-inline-block">
                <div className="mr-1 float-md-left btn-group mb-1">
                  <DatePicker
                    className={"custom-datepicker"}
                    calendarIcon={<i className="simple-icon-calendar" />}
                    clearIcon={<i className="iconsminds-close" />}
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    format="dd/MM/y"
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 100)
                      )
                    }
                    value={examDateSelect ? examDateSelect : null}
                    tileClassName={({ activeStartDate, date, view }) => {
                      if (
                        examColumns.includes(moment(date).format("YYYY-MM-DD"))
                      ) {
                        // console.log(moment(date).format("YYYY-MM-DD"));
                        return "highlight";
                      }
                    }}
                    showLeadingZeros={true}
                    onChange={(v) => {
                      console.log(v);
                      setExamDateSelect(v);
                      getListWithDate(moment(v).format("DD/MM/YYYY"));
                    }}
                  />
                </div>

                <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                  <DropdownToggle caret color="outline-dark" size="xs">
                    Venue:
                    {venueColumn ? venueColumn : ""}
                  </DropdownToggle>
                  <DropdownMenu>
                    {venueColumns.map((venue, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          onClick={() => getListWithVenue(venue)}
                        >
                          {venue}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                  {/* <div className="d-block d-md-inline-block pt-1">
                <div className="search-sm d-inline-block mb-1 "> */}
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={"Search"}
                    onKeyPress={(e) => onSearchKey(e)}
                  />
                  {/* </div> */}

                  {/* </div> */}
                </div>
                <span className="ml-2"> Total: {count}</span>
              </div>

              <div className="d-flex text-zero top-right-button-container">
                <div>
                  {/* <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      color="primary"
                      // className="btn-xs"
                      outline
                    >
                      Actions
                    </DropdownToggle>
                    <DropdownMenu end>
                     
                      <DropdownItem
                        disabled={downloadLoading}
                        onClick={handleDownload}
                      >
                        {downloadLoading ? "Loading..." : "Download"}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                </div>
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {count === null && (
            <p>Please search for the data from the input above. </p>
          )}
          {!loading && count !== null && data.length === 0 ? (
            <p> List is empty</p>
          ) : (
            data.map((user) => (
              <Colxx xxs="12" key={user._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <span
                        onClick={() => handleClickEor(user._id)}
                        className="w-40 w-sm-100 clickable"
                      >
                        <p className="list-item-heading mb-1 truncate">
                          {user.GIVEN_NAME} {user.FAMILY_NAME}
                        </p>
                      </span>
                      <span
                        onClick={() => handleClickEor(user._id)}
                        className="w-15 w-sm-100 clickable"
                      >
                        <p className="mb-1 text-small">
                          {user.CANDIDATE_NUMBER}{" "}
                          {user.eorSent && (
                            <>
                              <i
                                className={"simple-icon-check"}
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  padding: "3px",
                                  borderRadius: "50%",
                                }}
                              />
                              <br />
                              {moment(user.eorSentDate).format("DD/MM/YYYY")}
                            </>
                          )}
                        </p>
                      </span>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {user.CANDIDATE_ID}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        Exam: {user.TEST_DATE}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {user.TEST_FORMAT} - {user.MODULE_NAME}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {user.EXAMINER_NAME} | {user.ORAL_SESSION_DATE} -{" "}
                        {user.START_TIME}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        <Button onClick={() => handleClickEor(user._id)}>
                          Send EOR
                        </Button>
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
          <Colxx xxs="12" className="mb-3">
            {!loading && data.length !== 0 && pages !== page && (
              <div className="col-12">
                <Button
                  color="primary"
                  className="top-right-button"
                  onClick={handleLoadMore}
                >
                  More...
                </Button>
              </div>
            )}
          </Colxx>
        </Row>

        {eorModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <EORCandidate toggle={toggleEorModal} candidateId={candidateId} />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default CandidatesListEOR;
