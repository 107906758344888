import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { CKEditor } from "ckeditor4-react";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import bareAxios from "axios";
import axios from "axiosClient";
import { reactSelectChange } from "utils/reactSelectChange";
import { currencyFormatter } from "utils/formatter";

const editorConfig = {
  toolbar: [
    ["Undo", "Redo"],
    ["Format", "Font", "FontSize"],
    ["TextColor", "BGColor"],
    ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
    ["Bold", "Italic", "Underline", "Strike", "RemoveFormat", "CopyFormatting"],
    ["Link", "Unlink"],
    [
      "NumberedList",
      "BulletedList",
      "-",
      "Outdent",
      "Indent",
      "-",
      "Blockquote",
    ],
    ["Image", "Table"],
    ["Scayt"],
  ],
  extraPlugins: "justify,format,font,colorbutton",
};

const EORCandidate = (props) => {
  const { toggle, candidateId } = props;
  const user = useUser();

  const initialValues = {};

  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [sections, setSections] = useState(false);

  const [showEditor, setShowEditor] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [body, setBody] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("Your EOR Request Registered");

  const handleGenerate = async () => {
    const eorPrice = await bareAxios.get(
      `https://tktapi.estudent.uz/api/price?exam=IELTS&module=EOR%20(re-mark)`
    );

    let text = `<p><strong>Dear ${values.GIVEN_NAME} ${
      values.FAMILY_NAME
    }</strong></p>

<p>This is a confirmation that the Edu-Action Exams Centre has now received all the relevant information and payment, and your EOR request has been registered.</p>
    
<p><strong>Amount received: ${currencyFormatter(
      eorPrice.data.priceAmount + "",
      "UZS"
    )} <br />
Candidate number: ${values.CANDIDATE_NUMBER} <br />
Chosen section(s): ${sections.join(", ")}</strong></p>
    
<p>Your EOR outcome will be available between 2 to 21 days. If you have not received a response after 21 days from the date of this email, please contact us.</p>
    
<p>Best regards,<br />
Edu-Action Exams Centre UZ004</p>
    
<p>Phone: +99871 230-01-03<br />
Email:&nbsp;ielts@edu-action.uz<br />
Website: exams.uz<br />
Address:&nbsp;2-3 Bunyodkor Avenue,<br />
CIU Building 4th floor,<br />
Tashkent city, Uzbekistan 100043</p>`;
    setBody(text);
    setShowEditor(true);
    setGenerated(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    axios
      .post(
        `/api/eor/email`,
        { email, subject, content: body },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (result) => {
        // console.log(result);
        if (result.status === 200) {
          setErrorMessage("");
          await axios.post(
            `/api/eor-candidate/${candidateId}`,
            { eorSent: true },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          setSuccess(true);
          toast.success("Successfully sent");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 400) {
          toast.error(err.response?.data?.error);
        } else {
          setErrorMessage(Object.values(err.response.data.errors)[0]);
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    if (candidateId) {
      axios
        .get(`/api/candidate/${candidateId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setValues(result.data);
          setEmail(result.data.EMAILID);
          setLoading(false);
        });
    } else {
      setValues(initialValues);
      setLoading(false);
    }
  }, [candidateId]);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>Send EOR</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form className="text-left">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Candidate Number</Label>

                        <Input
                          type="text"
                          name="newRegion"
                          value={values.CANDIDATE_NUMBER}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label>Sections</Label>

                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="getCertificatePlace"
                          onChange={(e) => setSections(reactSelectChange(e))}
                          options={[
                            { label: "Listening", value: "Listening" },
                            { label: "Reading", value: "Reading" },
                            { label: "Writing", value: "Writing" },
                            { label: "Speaking", value: "Speaking" },
                          ]}
                          isMulti
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12" className="text-left">
                      <div className="no-wrap">
                        <Button
                          color="secondary"
                          size="xs"
                          onClick={handleGenerate}
                        >
                          Generate
                        </Button>
                      </div>
                    </Col>
                    {generated && (
                      <Col md="12" className="text-left">
                        <Separator className="mb-5" />

                        <Col md="6">
                          <FormGroup>
                            <Label>Email</Label>
                            <Input
                              type="text"
                              name="newRegion"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Email Subject</Label>
                            <Input
                              type="text"
                              name="subject"
                              value={subject}
                              onChange={(e) => setSubject(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <h2>Content</h2>
                            {showEditor && (
                              <CKEditor
                                initData={body}
                                onChange={(event) =>
                                  setBody(event.editor.getData())
                                }
                                config={editorConfig}
                              />
                            )}
                          </FormGroup>
                        </Col>
                      </Col>
                    )}
                  </Row>
                </Form>

                <div className="mt-3">
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={toggle}
                  >
                    Close
                  </Button>
                  {body !== "" && (
                    <Button
                      color={success ? "success" : "primary"}
                      className="top-right-button ml-2"
                      onClick={handleSubmit}
                      disabled={success}
                    >
                      {success ? "Done" : `Send`}
                    </Button>
                  )}
                </div>
                <div>{errorMessage}</div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default EORCandidate;
