import { Colxx, Separator } from "components/common/CustomBootstrap";
import AppLayout from "layout/AppLayout";
import React, { useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { PDFDocument } from "pdf-lib";
import { toast } from "react-toastify";
import fontkit from "@pdf-lib/fontkit";
// import customFontUrl from "/assets/Kind Regards.ttf";

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const BatchHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({
    start: null,
    end: null,
    bhStart: 1,
    absents: "",
    exceptions: "",
    listening: "",
    reading: "",
    writing: "",
  });

  const [listeningReading, setListeningReading] = useState([]);
  const [writing, setWriting] = useState([]);
  const [pdfValues, setPdfValues] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      setFile(await convertToBase64(e.target.files[0]));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (pdfValues.length === 0) {
      toast.error("You need to generate numbers first");
      return;
    }
    if (!file) {
      toast.error("You need to choose the pdf batch header");
      return;
    }
    setLoading(true);
    try {
      // console.log("file", file);
      // console.log("pdf values", pdfValues);
      const pdfDoc = await PDFDocument.load(file);
      pdfDoc.registerFontkit(fontkit);
      const fontResponse = await fetch("/assets/Kind Regards.ttf");
      const fontData = await fontResponse.arrayBuffer();
      const cursiveFont = await pdfDoc.embedFont(fontData);
      const pages = pdfDoc.getPages();

      for (let i = 0; i < pdfValues.length; i++) {
        const currentPage = pages[i];
        if (!currentPage) {
          break;
        }
        const currentValues = pdfValues[i];
        if (currentValues.listeningReading) {
          currentPage.drawText(currentValues.listeningId, {
            x: 234,
            y: 326,
            size: 20.5,
            font: cursiveFont,
          });
          currentPage.drawText(currentValues.readingId, {
            x: 234,
            y: 289,
            size: 20.5,
            font: cursiveFont,
          });
        } else if (currentValues.writing) {
          currentPage.drawText(currentValues.writingId, {
            x: 234,
            y: 253,
            size: 20.5,
            font: cursiveFont,
          });
        }
        currentPage.drawText(currentValues.count, {
          x: 234,
          y: 197,
          size: 21.5,
          font: cursiveFont,
        });
        currentPage.drawText(currentValues.start, {
          x: 234,
          y: 161,
          size: 21.5,
          font: cursiveFont,
        });
        currentPage.drawText(currentValues.end, {
          x: 234,
          y: 123,
          size: 21.5,
          font: cursiveFont,
        });
      }
      const pdfBytes = await pdfDoc.save();
      const url = window.URL.createObjectURL(new Blob([pdfBytes]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `Batch_Header-${
        new Date().toISOString().split("T")[0]
      }.pdf`; //or any other extension
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleGenerate = () => {
    // console.log("data", data);
    let listeningReadingTemp = [];
    let writingTemp = [];
    let pdfLR = [];
    let pdfW1 = [];
    let pdfW2 = [];
    let listeningReading20 = [];
    let writing20 = [];
    let absents = data.absents.split(",").map((item) => Number(item.trim()));
    let exceptions = data.exceptions
      .split(",")
      .map((item) => Number(item.trim()));
    for (let i = Number(data.start); i <= Number(data.end); i++) {
      if (!absents.includes(i)) {
        if (listeningReading20.length === 20) {
          listeningReadingTemp.push(
            `${listeningReading20[0]} - ${listeningReading20[19]}`
          );
          pdfLR.push({
            start: listeningReading20[0]
              .toString()
              .padStart(6, "0")
              .split("")
              .map((char) => char + "   ")
              .join("")
              .trim(),
            end: listeningReading20[19]
              .toString()
              .padStart(6, "0")
              .split("")
              .map((char) => char + "   ")
              .join("")
              .trim(),
            count: "2   1",
            listeningReading: true,
            writing: false,
            listeningId: data.listening
              .split("")
              .map((char) => (/\d/.test(char) ? char + "   " : char + "  "))
              .join("")
              .trim(),
            readingId: data.reading
              .split("")
              .map((char, index) =>
                /\d/.test(data.reading.charAt(index + 1))
                  ? char + "   "
                  : char + "  "
              )
              .join("")
              .trim(),
          });
          listeningReading20 = [i];
        } else {
          listeningReading20.push(i);
        }
        if (!exceptions.includes(i)) {
          if (writing20.length === 20) {
            writingTemp.push(`${writing20[0]} - ${writing20[19]}`);
            pdfW1.push({
              start: writing20[0]
                .toString()
                .padStart(6, "0")
                .split("")
                .map((char) => char + "   ")
                .join("")
                .trim(),
              end: writing20[19]
                .toString()
                .padStart(6, "0")
                .split("")
                .map((char) => char + "   ")
                .join("")
                .trim(),
              count: "2   1",
              listeningReading: false,
              writing: true,
              writingId: data.writing
                .split("")
                .map((char, index) =>
                  /\d/.test(data.reading.charAt(index + 1))
                    ? char + "   "
                    : char + "  "
                )
                .join("")
                .trim(),
            });
            pdfW2.push({
              start: writing20[0]
                .toString()
                .padStart(6, "0")
                .split("")
                .map((char) => char + "   ")
                .join("")
                .trim(),
              end: writing20[19]
                .toString()
                .padStart(6, "0")
                .split("")
                .map((char) => char + "   ")
                .join("")
                .trim(),
              count: "",
              listeningReading: false,
              writing: true,
              writingId: data.writing
                .split("")
                .map((char, index) =>
                  /\d/.test(data.reading.charAt(index + 1))
                    ? char + "   "
                    : char + "  "
                )
                .join("")
                .trim(),
            });
            writing20 = [i];
          } else {
            writing20.push(i);
          }
        }
      }
    }
    if (listeningReading20.length !== 0) {
      listeningReadingTemp.push(
        `${listeningReading20[0]} - ${
          listeningReading20[listeningReading20.length - 1]
        } (${listeningReading20.length})`
      );
      let count = Number(listeningReading20.length) + 1;
      pdfLR.push({
        start: "",
        end: "",
        count: "",
        listeningReading: true,
        writing: false,
        listeningId: data.listening
          .split("")
          .map((char) => (/\d/.test(char) ? char + "   " : char + "  "))
          .join("")
          .trim(),
        readingId: data.reading
          .split("")
          .map((char, index) =>
            /\d/.test(data.reading.charAt(index + 1))
              ? char + "   "
              : char + "  "
          )
          .join("")
          .trim(),
      });
    }
    if (writing20.length !== 0) {
      writingTemp.push(
        `${writing20[0]} - ${writing20[writing20.length - 1]} (${
          writing20.length
        })`
      );
      let count = Number(writing20.length) + 1;
      pdfW1.push({
        start: "",
        end: "",
        count: "",
        listeningReading: false,
        writing: true,
        writingId: data.writing
          .split("")
          .map((char, index) =>
            /\d/.test(data.reading.charAt(index + 1))
              ? char + "   "
              : char + "  "
          )
          .join("")
          .trim(),
      });
      pdfW2.push({
        start: "",
        end: "",
        count: "",
        listeningReading: false,
        writing: true,
        writingId: data.writing
          .split("")
          .map((char, index) =>
            /\d/.test(data.reading.charAt(index + 1))
              ? char + "   "
              : char + "  "
          )
          .join("")
          .trim(),
      });
    }
    setListeningReading(listeningReadingTemp);
    setWriting(writingTemp);
    setPdfValues([...pdfLR, ...pdfW1, ...pdfW2]);
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Batch Header Generate</h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <FormGroup row>
                        <Colxx sm="4">
                          <p style={{ textAlign: "left" }}>
                            <strong>Start from</strong>
                          </p>
                          <Input
                            type="number"
                            value={data.start}
                            onChange={(e) => {
                              setData({
                                ...data,
                                start: e.target.value,
                              });
                            }}
                          />
                        </Colxx>
                        <Colxx sm="4">
                          <p style={{ textAlign: "left" }}>
                            <strong>To</strong>
                          </p>
                          <Input
                            type="number"
                            value={data.end}
                            onChange={(e) => {
                              setData({
                                ...data,
                                end: e.target.value,
                              });
                            }}
                          />
                        </Colxx>
                        <Colxx sm="4">
                          <p style={{ textAlign: "left" }}>
                            <strong>Batch Header Start</strong>
                          </p>
                          <Input
                            type="number"
                            value={data.bhStart}
                            onChange={(e) => {
                              setData({
                                ...data,
                                bhStart: e.target.value,
                              });
                            }}
                          />
                        </Colxx>
                      </FormGroup>
                      <FormGroup row>
                        <Colxx sm="4">
                          <p style={{ textAlign: "left" }}>
                            <strong>Listening Test Version ID</strong>
                          </p>
                          <Input
                            type="text"
                            value={data.listening}
                            onChange={(e) => {
                              setData({
                                ...data,
                                listening: e.target.value,
                              });
                            }}
                          />
                        </Colxx>
                        <Colxx sm="4">
                          <p style={{ textAlign: "left" }}>
                            <strong>Reading Test Version ID</strong>
                          </p>
                          <Input
                            type="text"
                            value={data.reading}
                            onChange={(e) => {
                              setData({
                                ...data,
                                reading: e.target.value,
                              });
                            }}
                          />
                        </Colxx>
                        <Colxx sm="4">
                          <p style={{ textAlign: "left" }}>
                            <strong>Writing Test Version ID</strong>
                          </p>
                          <Input
                            type="text"
                            value={data.writing}
                            onChange={(e) => {
                              setData({
                                ...data,
                                writing: e.target.value,
                              });
                            }}
                          />
                        </Colxx>
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup row>
                        <Colxx sm="12" className="mb-2">
                          <p style={{ textAlign: "left" }}>
                            <strong>Absents (separated by comma)</strong>
                          </p>
                          <Input
                            type="text"
                            value={data.absents}
                            onChange={(e) => {
                              setData({
                                ...data,
                                absents: e.target.value,
                              });
                            }}
                          />
                        </Colxx>
                        <Colxx sm="12">
                          <p style={{ textAlign: "left" }}>
                            <strong>Exceptions (separated by comma)</strong>
                          </p>
                          <Input
                            type="text"
                            value={data.exceptions}
                            onChange={(e) => {
                              setData({
                                ...data,
                                exceptions: e.target.value,
                              });
                            }}
                          />
                        </Colxx>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <div className="mb-3">
          <Button
            color="light"
            className="top-right-button"
            onClick={() => navigate("/app/details")}
          >
            Back
          </Button>
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleGenerate}
          >
            Generate
          </Button>
        </div>
        <Card>
          <CardBody>
            <Row className="text-left">
              <Col sm="4">
                <h2>Listening & Reading</h2>
                {listeningReading.map((item, index) => (
                  <span key={index}>
                    {index + Number(data.bhStart)}) {item}
                    <br />
                  </span>
                ))}
              </Col>
              <Col sm="4">
                <h2>Writing T1</h2>
                {writing.map((item, index) => (
                  <span key={index}>
                    {index + Number(data.bhStart) + listeningReading.length}){" "}
                    {item}
                    <br />
                  </span>
                ))}
              </Col>
              <Col sm="4">
                <h2>Writing T2</h2>
                {writing.map((item, index) => (
                  <span key={index}>
                    {index +
                      Number(data.bhStart) +
                      listeningReading.length +
                      writing.length}
                    ) {item}
                    <br />
                  </span>
                ))}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mt-4">
          <CardBody>
            <div>
              <label>
                <span className="required mr-2">Select PDF Batch Header: </span>
                <input
                  type="file"
                  className="text"
                  name="file"
                  placeholder="PDF"
                  tabIndex="1"
                  onChange={handleFileChange}
                  required
                />
              </label>
            </div>
            <Button
              color="primary"
              className="top-right-button mt-2"
              disabled={loading}
              onClick={handleSubmit}
            >
              Let the Magic Happen
            </Button>
          </CardBody>
        </Card>
      </div>
    </AppLayout>
  );
};

export default BatchHeader;
