import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";
import AppLayout from "layout/AppLayout";

const EditCandidatePage = (props) => {
  const { id: candidateId } = useParams();
  const user = useUser();
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleChange = (e) => {
    setUpdated(true);
    const { name, value } = e.target;

    if (name === "TELEPHONE") {
      setValues({
        ...values,
        [name]: value,
        MOBILE: value,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `/api/candidate/${candidateId}`,
        { ...values, allow: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        // console.log(result);
        if (result.status === 200) {
          setErrorMessage("");
          setSuccess(true);
          toast.success("Successfully updated");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 400) {
          toast.error(err.response?.data?.error);
        } else {
          setErrorMessage(Object.values(err.response.data.errors)[0]);
          toast.error("Something went wrong");
        }
      });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    axios
      .delete(`/api/candidate/${candidateId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        // console.log(result);
        if (result.status === 200) {
          setErrorMessage("");

          toast.success("Successfully deleted");
          navigate(-1);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 400) {
          toast.error(err.response?.data?.error);
        } else {
          setErrorMessage(Object.values(err.response.data.errors)[0]);
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/candidate/${candidateId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setValues(result.data);
        setLoading(false);
      });
  }, [candidateId]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{candidateId ? "Update Details" : "Add Candidate"}</h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form className="text-left">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Name</Label>
                          <Input
                            type="text"
                            name="GIVEN_NAME"
                            value={values.GIVEN_NAME}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Surname</Label>
                          <Input
                            type="text"
                            name="FAMILY_NAME"
                            value={values.FAMILY_NAME}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Passport</Label>
                          <Input
                            type="text"
                            name="CANDIDATE_ID"
                            value={values.CANDIDATE_ID}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Exam Date</Label>
                          <Input
                            type="text"
                            name="TEST_DATE"
                            value={values.TEST_DATE}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Candidate Number</Label>
                          <Input
                            type="number"
                            name="CANDIDATE_NUMBER"
                            value={values.CANDIDATE_NUMBER}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Phone Number</Label>
                          <Input
                            type="text"
                            name="TELEPHONE"
                            value={values.TELEPHONE}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Email</Label>
                          <Input
                            type="text"
                            name="EMAILID"
                            value={values.EMAILID}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>County</Label>
                          <Input
                            type="text"
                            name="COUNTY_STATE"
                            value={values.COUNTY_STATE}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>City</Label>
                          <Input
                            type="text"
                            name="TOWN_CITY"
                            value={values.TOWN_CITY}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Addres Line 1</Label>
                          <Input
                            type="text"
                            name="ADDRESS_LINE1"
                            value={values.ADDRESS_LINE1}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Addres Line 2</Label>
                          <Input
                            type="text"
                            name="ADDRESS_LINE2"
                            value={values.ADDRESS_LINE2}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>PostCode</Label>
                          <Input
                            type="number"
                            name="POSTCODE"
                            value={values.POSTCODE}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>How to get the Certificate?</Label>
                          <Input
                            type="text"
                            name="getCertificateType"
                            value={values.getCertificateType}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      {values.getCertificateType === "From Partner" && (
                        <Col md="6">
                          <FormGroup>
                            <Label>Region </Label>
                            <Input
                              type="text"
                              name="getCertificateRegion"
                              value={values.getCertificateRegion}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      {values.getCertificateRegion && (
                        <Col md="6">
                          <FormGroup>
                            <Label>Place </Label>
                            <Input
                              type="text"
                              name="getCertificatePlace"
                              value={values.getCertificatePlace}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  </Form>

                  <div className="mt-3">
                    {user.role === "Admin" && (
                      <Button
                        color={"danger"}
                        className="top-right-button ml-2"
                        onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    )}
                    {updated && (
                      <Button
                        color={success ? "success" : "primary"}
                        className="top-right-button ml-2"
                        onClick={handleSubmit}
                        disabled={success}
                      >
                        {success ? "Done" : "Update"}
                      </Button>
                    )}
                  </div>
                  <div>{errorMessage}</div>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
      </div>
    </AppLayout>
  );
};

export default EditCandidatePage;
