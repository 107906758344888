import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import Select from "react-select";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";
import { toBase64 } from "utils/toBase64";
import { generateZigzagCells } from "utils/cellsGenerate";

const EditRoom = (props) => {
  const { toggle, roomId, values, setValues } = props;
  const user = useUser();

  const [thisValues, setThisValues] = useState({});
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();
  const [isEven, setIsEven] = useState(false);

  const [success, setSuccess] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleChange = (e) => {
    setUpdated(true);
    const { name, value } = e.target;
    setThisValues({
      ...thisValues,
      [name]: value,
    });
  };

  const handleFile = async (e) => {
    let file = e.target.files[0];
    // console.log(fileType);
    try {
      setLoading(true);
      const result = await toBase64(file);
      let { data } = await axios.post(
        "/api/upload-file",
        {
          file: {
            extension: file.name.split(".")[file.name.split(".").length - 1],
            type: file.type,
            base64Data: result,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //set image in te state
      setFile(data);
      setLoading(false);
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!thisValues.name) {
      toast.error("Name and Room ID cannot be empty");
    } else {
      let supervisorCells = generateZigzagCells(thisValues.supervisorCellsUI);
      let invigilatorCells = generateZigzagCells(thisValues.invigilatorCellsUI);
      let candidateCells = generateZigzagCells(
        thisValues.candidateCellsUI,
        true,
        thisValues.exceptionsUI.replace(/\s/g, "").split(","),
        isEven
      );
      console.log(candidateCells);
      axios
        .post(
          `/api/room/${roomId}`,
          {
            ...thisValues,
            ...(file.Location ? { excelFile: file } : {}),
            supervisorCells,
            invigilatorCells,
            candidateCells,
            isEven,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          // console.log(result);
          if (result.status === 200) {
            setSuccess(true);
            toast.success(
              roomId ? "Successfully updated" : "Successfully added"
            );
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          // console.log(err.response.data);
          toast.error(Object.values(err.response.data.errors)[0]);
          toast.error("Something went wrong");
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    if (roomId) {
      axios
        .get(`/api/room/${roomId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setThisValues(result.data);
          setIsEven(result.data.isEven);
          setLoading(false);
        })
        .catch((e) => {
          toast.error("something went wrong");
        });
    } else {
      // setValues(initialValues);
      setLoading(false);
    }
  }, [roomId]);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>{roomId ? "Update Details" : "Add Room"}</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form className="text-left">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Name</Label>
                        <Input
                          type="text"
                          name="name"
                          value={thisValues.name}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Venue Name</Label>
                        <Input
                          type="text"
                          name="venueName"
                          value={thisValues.venueName}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Address</Label>
                        <Input
                          type="text"
                          name="address"
                          value={thisValues.address}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Location Link</Label>
                        <Input
                          type="text"
                          name="locationLink"
                          value={thisValues.locationLink}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Start Time</Label>
                        <Input
                          type="text"
                          name="startTime"
                          value={thisValues.startTime}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label>Attach Excel file</Label>
                        <Input
                          type="file"
                          name="excelFile"
                          onChange={handleFile}
                          disabled={loading}
                        />
                      </FormGroup>
                      {thisValues.excelFile && thisValues.excelFile.Key && (
                        <span>
                          <b>Existing:</b> {thisValues.excelFile.Key}
                          <Button
                            size="xs"
                            className="ml-2"
                            color="light"
                            onClick={() =>
                              window.open(
                                thisValues.excelFile.Location,
                                "_blank"
                              )
                            }
                          >
                            Download
                          </Button>
                        </span>
                      )}
                      {file.Key && (
                        <span>
                          <b>Uploaded:</b> {file.Key}
                        </span>
                      )}
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Test Date Cell (e.g. D3)</Label>
                        <Input
                          type="text"
                          name="testDateCell"
                          value={thisValues.testDateCell}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Supervisors Cells From:To (e.g. D7:D8)</Label>
                        <Input
                          type="text"
                          name="supervisorCellsUI"
                          value={thisValues.supervisorCellsUI}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Invigilators Cells From:To (e.g. F4:G8)</Label>
                        <Input
                          type="text"
                          name="invigilatorCellsUI"
                          value={thisValues.invigilatorCellsUI}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Candidates Cells From:To (e.g. C17:H24)</Label>
                        <Input
                          type="text"
                          name="candidateCellsUI"
                          value={thisValues.candidateCellsUI}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Is the first column number even?</Label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="isSupervisor"
                          value={{
                            label: isEven ? "Yes" : "No",
                            value: values.isEven,
                          }}
                          onChange={(e) => {
                            setIsEven(e.value);
                          }}
                          options={[
                            { label: "Yes", value: true },
                            { label: "No", value: false },
                          ]}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label>
                          Candidates Exceptional Cells (e.g. D20, G18, E, H, E5)
                        </Label>
                        <Input
                          type="text"
                          name="exceptionsUI"
                          value={thisValues.exceptionsUI}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>

                <div className="mt-3">
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={toggle}
                  >
                    Close
                  </Button>
                  {updated && (
                    <Button
                      color={success ? "success" : "primary"}
                      className="top-right-button ml-2"
                      onClick={handleSubmit}
                      disabled={success}
                    >
                      {success ? "Done" : `${roomId ? "Update" : "Add"}`}
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default EditRoom;
