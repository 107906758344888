/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { Button } from "reactstrap";

const VolumeDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [token, setToken] = useToken();

  const handleGetDetails = () => {
    let isMounted = true;
    setLoading(true);
    axios
      .get(`/api/partner-orgs/${props.organization}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          if (result.status === 200) {
            setData(result.data);
            setLoading(false);
            setShow(true);
          }
        }
      });
    return () => {
      isMounted = false;
    };
  };

  return (
    <>
      {!show && (
        <Button outline size="xs" onClick={handleGetDetails}>
          View Details
        </Button>
      )}

      {loading && <p>Loading...</p>}

      {show && (
        <>
          All: {data.allCount}
          <br />
          Confirmed: {data.confirmedCount}
          <br />
          Rejected: {data.rejectedCount}
          <br />
          Processing: {data.processingCount}
        </>
      )}
    </>
  );
};

export default VolumeDetails;
