import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useToken } from "auth/useToken";

const InspectList = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [word, setWord] = useState(1);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (word) => {
    setWord(word);
    ///
    axios
      .get(`https://new.exams.uz/api/payment/list?search=${word}`)
      .then((result) => {
        setData(result.data.data);
        setCount(result.data._meta.totalCount);
        setPages(result.data._meta.pageCount);
        setPage(result.data._meta.currentPage);
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    axios
      .get(
        `https://new.exams.uz/api/payment/list?${
          word ? `search=${word}` : ""
        }&page=${page + 1}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData([...data, ...result.data.data]);
        setCount(result.data._meta.totalCount);
        setPages(result.data._meta.pageCount);
        setPage(page + 1);
        setLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get(`https://new.exams.uz/api/payment/list?sort=-pinflUpdated`)
      .then((result) => {
        setData(result.data.data);
        setCount(result.data._meta.totalCount);
        setPages(result.data._meta.pageCount);
        setPage(result.data._meta.currentPage);
        setLoading(false);
      });
  }, []);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="d-flex justify-content-between">
              <div className="mb-2">
                <h1>Payments List</h1>
              </div>
              <div className="mb-2">
                <div className="d-block d-md-inline-block pt-1">
                  <div className="search-sm d-inline-block mb-1 ">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder={"Search"}
                      onKeyPress={(e) => onSearchKey(e)}
                    />
                  </div>
                  <> Total: {count}</>
                </div>
              </div>
            </div>
            <Separator className="mb-2" />
            <div className="text-zero top-right-button-container">
              <Button
                color="primary"
                className="top-right-button mb-2"
                onClick={() => navigate("candidates")}
              >
                Candidates
              </Button>
            </div>
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {count === null && (
            <p>Please search for the data from the input above. </p>
          )}
          {!loading && count !== null && data.length === 0 ? (
            <p> List is empty</p>
          ) : (
            data.map((user) => (
              <Colxx xxs="12" key={user.id} className="mb-3 text-left">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <NavLink to={`${user.id}`} className="w-30 w-sm-100">
                        <p className="list-item-heading mb-1 truncate">
                          {user.name} {user.surname}
                        </p>
                      </NavLink>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {user.passport}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        Exam: {user.exam_date}
                      </p>
                      <p className="mb-1 text-muted text-small w-25 w-sm-100">
                        {user.pinfl ? `PINFL: ${user.pinfl}` : ""}
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
          <Colxx xxs="12" className="mb-3">
            {!loading && data.length !== 0 && pages !== page && (
              <div className="col-12">
                <Button
                  color="primary"
                  className="top-right-button"
                  onClick={handleLoadMore}
                >
                  More...
                </Button>
              </div>
            )}
          </Colxx>
        </Row>
      </div>
    </AppLayout>
  );
};

export default InspectList;
