const condition = (isEven, col) => {
  if (isEven) {
    return col % 2 === 0;
  } else {
    return col % 2 !== 0;
  }
};

export function generateZigzagCells(
  range,
  zigzag = false,
  exceptions = [],
  isEven
) {
  const [startCol, startRow, endCol, endRow] = parseRange(range);
  const cells = [];

  for (let col = startCol; col <= endCol; col++) {
    const colLetter = colToLetter(col);

    if (exceptions.includes(colLetter)) {
      continue; // Skip the entire column if it's in the exceptions list
    }
    if (zigzag) {
      if (condition(isEven, col)) {
        for (let row = startRow; row <= endRow; row++) {
          const cell = `${colLetter}${row}`;
          if (!isException(cell, exceptions)) {
            cells.push(cell);
          }
        }
      } else {
        for (let row = endRow; row >= startRow; row--) {
          const cell = `${colLetter}${row}`;
          if (!isException(cell, exceptions)) {
            cells.push(cell);
          }
        }
      }
    } else {
      for (let row = startRow; row <= endRow; row++) {
        const cell = `${colLetter}${row}`;
        if (!isException(cell, exceptions)) {
          cells.push(cell);
        }
      }
    }
  }

  return cells;
}

function isException(cell, exceptions) {
  return exceptions.some((exception) => {
    if (exception.includes(":")) {
      // Handle range exceptions (e.g., "D20:G22")
      const [startCell, endCell] = exception.split(":");
      const [startCol, startRow] = parseRange(startCell);
      const [endCol, endRow] = parseRange(endCell);
      const [col, row] = parseRange(cell);
      return (
        col >= startCol && col <= endCol && row >= startRow && row <= endRow
      );
    } else {
      // Handle individual cell exceptions (e.g., "E5")
      return cell === exception;
    }
  });
}

function parseRange(range) {
  const [start, end] = range.split(":");
  const startCol = letterToCol(start.match(/[A-Z]+/)[0]);
  const startRow = parseInt(start.match(/\d+/)[0], 10);
  const endCol = letterToCol(end.match(/[A-Z]+/)[0]);
  const endRow = parseInt(end.match(/\d+/)[0], 10);
  return [startCol, startRow, endCol, endRow];
}

function colToLetter(col) {
  let letter = "";
  while (col > 0) {
    const remainder = (col - 1) % 26;
    letter = String.fromCharCode(65 + remainder) + letter;
    col = Math.floor((col - 1) / 26);
  }
  return letter;
}

function letterToCol(letter) {
  let col = 0;
  for (let i = 0; i < letter.length; i++) {
    col = col * 26 + (letter.charCodeAt(i) - 64);
  }
  return col;
}
