import React from "react";
import { useDraggable } from "@dnd-kit/core";

const Draggable = ({ name, uniqueId, dragOverlay }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: uniqueId,
  });

  // transform ? disableBodyScroll(document) : enableBodyScroll(document);

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        cursor: "grabbing",
      }
    : {
        cursor: "grab",
      };

  return (
    <div
      className="draggable"
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {name}
    </div>
  );
};

export default Draggable;
