import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import SignatureCanvas from "react-signature-canvas";
import Select from "react-select";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";

const EditCandidate = (props) => {
  const { toggle, candidateId } = props;
  const user = useUser();

  const initialValues = {
    COUNTY_STATE: "",
    TOWN_CITY: "",
    ADDRESS_LINE2: "",
    ADDRESS_LINE1: "",
    POSTCODE: "",
  };

  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState({});

  const [regionsOptions, setRegionsOptions] = useState([]);
  const [placesOptions, setPlacesOptions] = useState([]);
  const [addRegion, setAddRegion] = useState(false);
  const [addPlace, setAddPlace] = useState(false);
  const [showPlace, setShowPlace] = useState(false);
  const [newRegion, setNewRegion] = useState("");
  const [newPlace, setNewPlace] = useState("");

  const populateRegions = () => {
    axios
      .get(`/api/candidate-regions`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        let options = [];
        result.data.forEach((element) => {
          options.push({ label: element.name, value: element.name });
        });
        setRegionsOptions(options);
      });
  };

  const populatePlaces = (region) => {
    axios
      .get(`/api/candidate-places?region=${region}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        // console.log(result.data);
        let placeOptions = [];
        result.data.forEach((element) => {
          placeOptions.push({ label: element.name, value: element.name });
        });
        setPlacesOptions(placeOptions);
        setShowPlace(true);
      });
  };

  const handleAddRegion = () => {
    axios
      .post(
        `/api/candidate-region/create`,
        { name: newRegion },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        let options = regionsOptions;
        options.push({ label: newRegion, value: newRegion });
        setRegionsOptions(options);
        setValues({ ...values, getCertificateRegion: newRegion });
        setAddRegion(false);
        toast.success("Successfully added");
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(
          err.response.data ? err.response.data.message : "Something went wrong"
        );
      });
  };

  const handleAddPlace = () => {
    axios
      .post(
        `/api/candidate-place/create`,
        { region: values.getCertificateRegion, name: newPlace },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          let options = placesOptions;
          options.push({ label: newPlace, value: newPlace });
          setPlacesOptions(options);
          setValues({ ...values, getCertificatePlace: newPlace });
          setAddPlace(false);
          toast.success("Successfully added");
        } else {
          // console.log(result);
          toast.error(
            result.data.message ? result.data.message : "Something went wrong"
          );
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(
          err.response.data ? err.response.data.message : "Something went wrong"
        );
      });
  };

  let signPad = {};

  const clear = () => {
    signPad.clear();
  };

  const trim = () => {
    setUpdated(true);
    handleImage(signPad.getTrimmedCanvas().toDataURL("image/png"));
    toast.warning("Don't forget to press the Update button");
  };

  const handleImage = async (url) => {
    setUploadLoading(true);
    try {
      let { data } = await axios.post(
        "/api/upload-image",
        {
          image: url,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //set image in te state
      setUploadedImg(data);
      setUploadLoading(false);
      toast.success("The signature successfully uploaded");
    } catch (err) {
      console.log(err);
      setUploadLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleChange = (e) => {
    setUpdated(true);
    const { name, value } = e.target;

    if (name === "TELEPHONE") {
      setValues({
        ...values,
        [name]: value,
        MOBILE: value,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    if (values.signature) {
      data = { ...values, updatedBy: user.username };
    } else {
      data = {
        ...values,
        signature: uploadedImg,
        signed: Object.keys(uploadedImg).length !== 0,
        updatedBy: user.username,
      };
    }
    axios
      .post(`/api/candidate/${candidateId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        // console.log(result);
        if (result.status === 200) {
          setErrorMessage("");
          setSuccess(true);
          toast.success(
            candidateId ? "Successfully updated" : "Successfully added"
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 400) {
          toast.error(err.response?.data?.error);
        } else {
          setErrorMessage(Object.values(err.response.data.errors)[0]);
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    if (candidateId) {
      axios
        .get(`/api/candidate/${candidateId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setValues(result.data);
          setLoading(false);
          if (result.data.getCertificatePlace) {
            setShowPlace(true);
          }
        });
    } else {
      setValues(initialValues);
      setLoading(false);
    }
  }, [candidateId]);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>{candidateId ? "Update Details" : "Add Candidate"}</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form className="text-left">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Name</Label>
                        <Input
                          type="text"
                          name="GIVEN_NAME"
                          value={values.GIVEN_NAME}
                          disabled={candidateId}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Surname</Label>
                        <Input
                          type="text"
                          name="FAMILY_NAME"
                          value={values.FAMILY_NAME}
                          disabled={candidateId}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Passport</Label>
                        <Input
                          type="text"
                          name="CANDIDATE_ID"
                          value={values.CANDIDATE_ID}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Exam Date</Label>
                        <Input
                          type="text"
                          name="TEST_DATE"
                          value={values.TEST_DATE}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>PINPP</Label>
                        <Input
                          type="text"
                          name="pinpp"
                          value={values.pinpp}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    {!candidateId && (
                      <>
                        <Col md="6">
                          <FormGroup>
                            <Label>Candidate Number</Label>
                            <Input
                              type="number"
                              name="CANDIDATE_NUMBER"
                              value={values.CANDIDATE_NUMBER}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>County</Label>
                            <Input
                              type="text"
                              name="COUNTY_STATE"
                              value={values.COUNTY_STATE}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>City</Label>
                            <Input
                              type="text"
                              name="TOWN_CITY"
                              value={values.TOWN_CITY}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Addres Line 1</Label>
                            <Input
                              type="text"
                              name="ADDRESS_LINE1"
                              value={values.ADDRESS_LINE1}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Addres Line 2</Label>
                            <Input
                              type="text"
                              name="ADDRESS_LINE2"
                              value={values.ADDRESS_LINE2}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>PostCode</Label>
                            <Input
                              type="number"
                              name="POSTCODE"
                              value={values.POSTCODE}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )}
                    <Col md="6">
                      <FormGroup>
                        <Label>Phone Number</Label>
                        <Input
                          type="text"
                          name="TELEPHONE"
                          value={values.TELEPHONE}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Email</Label>
                        <Input
                          type="text"
                          name="EMAILID"
                          value={values.EMAILID}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>How to get the Certificate?</Label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="getCertificateType"
                          value={{
                            label: values.getCertificateType,
                            value: values.getCertificateType,
                          }}
                          onChange={(e) => {
                            setUpdated(true);
                            if (e.value === "From Partner") {
                              populateRegions();
                              setValues({
                                ...values,
                                getCertificateType: e.value,
                              });
                            } else {
                              setAddRegion(false);
                              setShowPlace(false);
                              let tempValues = { ...values };
                              tempValues.getCertificateType = e.value;
                              tempValues.getCertificateRegion = null;
                              tempValues.getCertificatePlace = null;
                              setValues(tempValues);
                            }
                          }}
                          options={[
                            { label: "From Office", value: "From Office" },
                            { label: "From Partner", value: "From Partner" },
                            { label: "By Post", value: "By Post" },
                          ]}
                        />
                      </FormGroup>
                    </Col>
                    {values.getCertificateType === "From Partner" && (
                      <Col md="6">
                        <FormGroup>
                          <Label>Region </Label>
                          <i
                            className="simple-icon-plus ml-2 clickable"
                            onClick={() => setAddRegion(!addRegion)}
                          />
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="getCertificateRegion"
                            value={{
                              label: values.getCertificateRegion,
                              value: values.getCertificateRegion,
                            }}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                getCertificateRegion: e.value,
                              });
                              populatePlaces(e.value);
                              setShowPlace(true);
                            }}
                            options={regionsOptions}
                          />
                        </FormGroup>
                      </Col>
                    )}

                    {addRegion && (
                      <Col md="6">
                        <FormGroup>
                          <Label>New Region</Label>
                          <i
                            className="simple-icon-check ml-2 clickable"
                            onClick={handleAddRegion}
                          />
                          <Input
                            type="text"
                            name="newRegion"
                            value={newRegion}
                            onChange={(e) => setNewRegion(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    )}

                    {values.getCertificateRegion && showPlace && (
                      <Col md="6">
                        <FormGroup>
                          <Label>Place </Label>
                          <i
                            className="simple-icon-plus ml-2 clickable"
                            onClick={() => setAddPlace(!addPlace)}
                          />
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="getCertificatePlace"
                            value={{
                              label: values.getCertificatePlace,
                              value: values.getCertificatePlace,
                            }}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                getCertificatePlace: e.value,
                              })
                            }
                            options={placesOptions}
                          />
                        </FormGroup>
                      </Col>
                    )}

                    {addPlace && (
                      <Col md="6">
                        <FormGroup>
                          <Label>New Place</Label>
                          <i
                            className="simple-icon-check  ml-2 clickable"
                            onClick={handleAddPlace}
                          />
                          <Input
                            type="text"
                            name="newPlace"
                            value={newPlace}
                            onChange={(e) => setNewPlace(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    <Col md="12" className="text-left">
                      <div className="no-wrap">
                        <h2>Signature:</h2>
                        {!(uploadedImg.Location || values.signature) && (
                          <>
                            <Button
                              color="secondary"
                              className="mr-1"
                              size="xs"
                              onClick={clear}
                            >
                              Clear
                            </Button>
                            <Button
                              color="secondary"
                              size="xs"
                              onClick={trim}
                              disabled={uploadLoading}
                            >
                              Save
                            </Button>
                          </>
                        )}
                      </div>
                      {!(uploadedImg.Location || values.signature) && (
                        <SignatureCanvas
                          penColor="green"
                          canvasProps={{
                            width: 500,
                            height: 200,
                            className: "sign-canvas",
                          }}
                          ref={(ref) => {
                            signPad = ref;
                          }}
                        />
                      )}
                      {uploadedImg.Location ? (
                        <img alt="signature" src={uploadedImg.Location} />
                      ) : null}
                      {values.signature ? (
                        <img alt="signature" src={values.signature.Location} />
                      ) : null}
                    </Col>
                    <Col md="12" className="text-left">
                      <Separator className="mb-5" />
                      <h4>
                        <strong>Address:</strong> {values.COUNTY_STATE}{" "}
                        {values.TOWN_CITY} {values.ADDRESS_LINE2}{" "}
                        {values.ADDRESS_LINE1}. Post Code: {values.POSTCODE}{" "}
                      </h4>
                    </Col>
                  </Row>
                </Form>

                <div className="mt-3">
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={toggle}
                  >
                    Close
                  </Button>
                  {updated && (
                    <Button
                      color={success ? "success" : "primary"}
                      className="top-right-button ml-2"
                      onClick={handleSubmit}
                      disabled={success}
                    >
                      {success ? "Done" : `${candidateId ? "Update" : "Add"}`}
                    </Button>
                  )}
                </div>
                <div>{errorMessage}</div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default EditCandidate;
