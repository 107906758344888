import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useToken } from "auth/useToken";
import axios from "axiosClient";
import EditPartner from "./editModal";
import { toast } from "react-toastify";
import SaveCandidates from "./saveCandidatesModal";
import CandidatesList from "./candidatesList";
import ResultsDownload from "./resultsDownloadModal";
import AddCandidateModal from "./addCandidatesModal";

const PartnerDetails = () => {
  const location = useLocation();

  const id = location.pathname.split("/").slice(-1)[0];

  const [partner, setPartner] = useState({});
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();
  const [downloadModal, setDownloadModal] = useState(false);
  const [addCandidateModal, setAddCandidateModal] = useState(false);

  const navigate = useNavigate();

  const toggleDownloadModal = () => {
    setDownloadModal(!downloadModal);
  };

  const toggleAddCandidateModal = () => {
    setAddCandidateModal(!addCandidateModal);
  };

  useEffect(() => {
    setLoading(true);
    if (id) {
      axios
        .get(`/api/partner/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setPartner(result.data);
          setLoading(false);
        })
        .catch((e) => {
          toast.error("something went wrong");
        });
    }
  }, [id]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Partner Details</h1>
              <br />
              <h1>{partner?.organization}</h1>
              <div
                className="text-zero top-right-button-container"
                style={{ display: "flex" }}
              >
                <Button
                  color="light"
                  className="top-right-button ml-2"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <Button
                  color="primary"
                  className="top-right-button ml-2"
                  onClick={toggleAddCandidateModal}
                >
                  Add Candidate
                </Button>
                <Button
                  color="primary"
                  className="top-right-button ml-2"
                  onClick={toggleDownloadModal}
                >
                  Download Results
                </Button>
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && <CandidatesList partner={partner} partnerId={id} />}
        {downloadModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <ResultsDownload
                toggle={toggleDownloadModal}
                partnerId={id}
                partnerName={partner.organization}
              />
            </div>
          </div>
        )}
        {addCandidateModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <AddCandidateModal
                toggle={toggleAddCandidateModal}
                partnerId={id}
                partnerName={partner.organization}
              />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default PartnerDetails;
