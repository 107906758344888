import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
  CustomInput,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";
import moment from "moment";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

import EditExaminer from "./editExaminerModal";
import EditVenue from "./editVenueModal";

const initialValues = {
  name: "",
  status: true,
  slots: [],
};

const NewSessions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState("");

  const [id, setId] = useState(useParams().id);
  const [name, setName] = useState("");
  const [slotId, setSlotId] = useState("");
  const [slotOrder, setSlotOrder] = useState(null);
  const [vcs, setVcs] = useState(false);
  const [status, setStatus] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [sessionDate, setSessionDate] = useState(null);
  const [speakingFromDate, setSpeakingFromDate] = useState(null);
  const [speakingToDate, setSpeakingToDate] = useState(null);

  const [addExaminerModal, setAddExaminerModal] = useState(false);
  const [examinersOptions, setExaminersOptions] = useState([]);
  const [addVenueModal, setAddVenueModal] = useState(false);
  const [venuesOptions, setVenuesOptions] = useState([]);
  const [regionsOptions, setRegionsOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useToken();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(id);
    if (name === "") {
      toast.error("The name of the session must be filled");
    } else if (moment(speakingToDate) < moment(speakingFromDate)) {
      toast.error("The 'from' date should be less than 'to' date");
    } else if (!values.examinerId || !values.venueId) {
      toast.error("Examiner and Venue must be selected");
    } else {
      setSubmitting(true);
      let data = {
        id,
        name,
        status,
        vcs,
        sessionDate: id
          ? sessionDate
          : moment(sessionDate).format("DD/MM/YYYY"),
        speakingFrom: moment(speakingFromDate).format("YYYY-MM-DD"),
        speakingTo: moment(speakingToDate).format("YYYY-MM-DD"),
        speakingFromDate: moment(speakingFromDate).set({
          hour: 10,
          minute: 0,
        }),
        speakingToDate: moment(speakingToDate).set({ hour: 23, minute: 59 }),
        examinerId: values.examinerId._id,
        venueId: values.venueId._id,
        venue: values.venueId.name,
        region: values.region,
      };
      axios
        .post(`/api/speaking/add-sessions`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          if (result.status === 200) {
            setValues({ ...values, examinerId: null, venueId: null });
            setId(result.data.id);
            toast.success(
              "The session for the selected examiner and date range has been successfully added"
            );
          }
          setSubmitting(false);
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    if (id) {
      axios
        .get(`api/speaking/date/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          setName(result.data.name);
          setSessionDate(result.data.mainTestDate);
          setValues({ ...values, region: result.data.region });
        });
      setSpeakingFromDate(null);
      setSpeakingToDate(null);
    }
    axios
      .get(`api/speaking/examiners?available=true`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let options = [];
        result.data.forEach((examiner) => {
          options.push({
            label: examiner.name,
            value: `${examiner.staffId}|${examiner._id}`,
          });
        });
        setExaminersOptions(options);
      });

    axios
      .get(`api/speaking/venues?available=true`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let options = [];
        result.data.forEach((venue) => {
          options.push({
            label: venue.name,
            value: `${venue.placeId}|${venue._id}`,
          });
        });
        setVenuesOptions(options);
      });

    axios
      .get(`api/details/regions`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let options = [];
        result.data.forEach((region) => {
          options.push({
            label: region.name,
            value: region.name,
          });
        });
        setRegionsOptions(options);
      });
    setLoading(false);
  }, []);

  if (editModal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const toggleAddExaminerModal = () => {
    setAddExaminerModal(!addExaminerModal);
  };

  const toggleAddVenueModal = () => {
    setAddVenueModal(!addVenueModal);
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>New Session</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup row>
                          <Label sm="4" className="text-right">
                            Session Name
                          </Label>
                          <Colxx sm="8">
                            <Input
                              type="text"
                              name="name"
                              value={name}
                              disabled={id}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup row>
                          <Colxx sm="4" className="mt-2 text-right">
                            <Label>Region </Label>
                          </Colxx>
                          <Colxx sm="8">
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="region"
                              isDisabled={id}
                              value={{
                                label: values.region,
                                value: values.region,
                              }}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  region: e.value,
                                });
                              }}
                              options={regionsOptions}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup row>
                          <Label sm="4" className="text-right">
                            Main Test Date
                          </Label>
                          <Colxx sm="8">
                            {id && (
                              <Input
                                type="text"
                                name="sessionDate"
                                value={sessionDate}
                                disabled
                              />
                            )}
                            {!id && (
                              <DatePicker
                                name="sessionDate"
                                selected={
                                  sessionDate ? new Date(sessionDate) : null
                                }
                                dateFormat="dd/MM/yyyy"
                                onChange={(e) => setSessionDate(moment(e))}
                                placeholderText="dd/mm/yyyy"
                              />
                            )}
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label check>
                            <Input
                              className="itemCheck mb-0"
                              type="checkbox"
                              checked={vcs}
                              onChange={(event) => {
                                setVcs(event.target.checked);
                              }}
                              label="VCS"
                            />
                            Video Call Speaking
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup row>
                          <Label sm="4" className="text-right">
                            Speaking From
                          </Label>
                          <Colxx sm="8">
                            <DatePicker
                              name="speakingFrom"
                              selected={
                                speakingFromDate
                                  ? new Date(speakingFromDate)
                                  : null
                              }
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => setSpeakingFromDate(date)}
                              placeholderText="dd/mm/yyyy"
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4" className="text-right">
                            To
                          </Label>
                          <Colxx sm="8">
                            <DatePicker
                              name="speakingTo"
                              selected={
                                speakingToDate ? new Date(speakingToDate) : null
                              }
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => setSpeakingToDate(date)}
                              placeholderText="dd/mm/yyyy"
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup row>
                          <Colxx sm="4" className="mt-2 text-right">
                            <Label>Examiner </Label>
                            <i
                              className="simple-icon-plus ml-2 clickable"
                              onClick={toggleAddExaminerModal}
                            />
                          </Colxx>
                          <Colxx sm="8">
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="examiner"
                              value={{
                                label: values.examinerId?.name,
                                value: values.examinerId?.name,
                              }}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  examinerId: {
                                    name: e.label,
                                    staffId: e.value?.split("|")[0],
                                    _id: e.value.split("|")[1],
                                  },
                                });
                              }}
                              options={examinersOptions}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4" className="text-left mt-2">
                        <FormGroup>
                          <Label>
                            Examiner ID: {values?.examinerId?.staffId}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup row>
                          <Colxx sm="4" className="mt-2 text-right">
                            <Label>Venue </Label>
                            <i
                              className="simple-icon-plus ml-2 clickable"
                              onClick={toggleAddVenueModal}
                            />
                          </Colxx>
                          <Colxx sm="8">
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="venue"
                              value={{
                                label: values.venueId?.name,
                                value: values.venueId?.name,
                              }}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  venueId: {
                                    name: e.label,
                                    placeId: e.value?.split("|")[0],
                                    _id: e.value.split("|")[1],
                                  },
                                });
                              }}
                              options={venuesOptions}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4" className="text-left mt-2">
                        <FormGroup>
                          <Label>VenueID: {values?.venueId?.placeId}</Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>

                  <div>
                    <Button
                      color="light"
                      className="top-right-button"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className="top-right-button ml-2"
                      onClick={handleSubmit}
                      disabled={submitting}
                    >
                      {submitting ? "Submitting..." : "Add"}
                    </Button>
                  </div>

                  {addExaminerModal && (
                    <div className="in-modal">
                      <div className="modal-overlay"></div>
                      <div className="in-modal-content">
                        <EditExaminer
                          toggle={toggleAddExaminerModal}
                          examinerId={null}
                          examinersOptions={examinersOptions}
                          setExaminersOptions={setExaminersOptions}
                          values={values}
                          setValues={setValues}
                        />
                      </div>
                    </div>
                  )}
                  {addVenueModal && (
                    <div className="in-modal">
                      <div className="modal-overlay"></div>
                      <div className="in-modal-content">
                        <EditVenue
                          toggle={toggleAddVenueModal}
                          venueId={null}
                          venuesOptions={venuesOptions}
                          setVenuesOptions={setVenuesOptions}
                          values={values}
                          setValues={setValues}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
      </div>
    </AppLayout>
  );
};

export default NewSessions;
