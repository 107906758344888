import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";

const UsersList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useToken();

  useEffect(() => {
    let isMounted = true;
    axios
      .get("/api/admins", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          setData(result.data.data);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (word) => {
    ///
    axios
      .get(`/api/admins?search=${word}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setData(result.data.data);
        setLoading(false);
      });
  };
  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="d-flex justify-content-between">
              <div className="mb-2">
                <h1>Users List</h1>
              </div>
              <div className="mb-2">
                <div className="d-block d-md-inline-block pt-1">
                  <div className="search-sm d-inline-block mb-1 ">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder={"Search"}
                      onKeyPress={(e) => onSearchKey(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Separator className="mb-2" />

            <div className="text-zero top-right-button-container mb-2">
              <Button
                color="primary"
                className="top-right-button"
                onClick={() => navigate("add")}
              >
                Add new
              </Button>
            </div>
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {!loading && data.length === 0 ? (
            <p>List is empty</p>
          ) : (
            data.map((user) => (
              <Colxx xxs="12" key={user._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <NavLink to={`${user._id}`} className="w-40 w-sm-100">
                        <p className="list-item-heading mb-1 truncate">
                          {user.username}
                        </p>
                      </NavLink>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {user.role}
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
        </Row>
      </div>
    </AppLayout>
  );
};

export default UsersList;
