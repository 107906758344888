import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import EditVenue from "./editVenueModal";

const SpeakingVenuesList = () => {
  const [data, setData] = useState([]);
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useToken();
  const [editVenueModal, setEditVenueModal] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);

  const toggleEditVenueModal = () => {
    if (editVenueModal) {
      navigate(0);
    }
    setEditVenueModal(!editVenueModal);
  };

  const handleClickVenue = (id) => {
    setSelectedVenue(id);
    toggleEditVenueModal();
  };
  useEffect(() => {
    let isMounted = true;
    axios
      .get("/api/speaking/venues", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          setData(result.data);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (word) => {
    ///
    axios
      .get(`/api/speaking/venues?search=${word}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log(result.data.data);
        setData(result.data);
        setLoading(false);
      });
  };
  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Speaking Venues List</h1>
              <div className="text-zero top-right-button-container">
                <Button
                  color="primary"
                  className="top-right-button"
                  onClick={() => handleClickVenue(null)}
                >
                  Add new
                </Button>
              </div>
            </div>
            {/* <div className="mb-2">
              <div className="d-block d-md-inline-block pt-1">
                <div className="search-sm d-inline-block mb-1 ">
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={"Search"}
                    onKeyPress={(e) => onSearchKey(e)}
                  />
                </div>
              </div>
            </div> */}
            <Separator className="mb-1" />
            <div className="ml-2 d-block d-flex">
              <Button
                color="primary"
                className="top-right-button align-left mb-2"
                onClick={() => navigate("/app/speaking")}
              >
                Sessions
              </Button>
              <Button
                color="primary"
                className="top-right-button align-left mb-2 ml-2"
                onClick={() => navigate("/app/speaking/examiners")}
              >
                Examiners
              </Button>
              <Button
                color="primary"
                className="top-right-button align-left mb-2 ml-2"
                onClick={() => navigate("/app/rooms")}
              >
                Rooms
              </Button>
            </div>
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {!loading && data.length === 0 ? (
            <p>List is empty</p>
          ) : (
            data.map((venue) => (
              <Colxx xxs="12" key={venue._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <span
                        onClick={() => handleClickVenue(venue._id)}
                        className="w-40 w-sm-100 clickable"
                      >
                        <p className="list-item-heading mb-1 truncate">
                          {venue.name}
                        </p>
                      </span>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {venue.placeId}
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
        </Row>
        {editVenueModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <EditVenue
                toggle={toggleEditVenueModal}
                venueId={selectedVenue}
                values={values}
                setValues={setValues}
              />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default SpeakingVenuesList;
