import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import { read, utils } from "xlsx";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import { useToken } from "auth/useToken";
import { toast } from "react-toastify";
import moment from "moment";

const ImportPbModal = ({ toggle, regionId }) => {
  const navigate = useNavigate();

  const [dates, setDates] = useState([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [token, setToken] = useToken();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      if (errorMessage === "") {
        axios
          .post(
            `/api/calendar/upload-dates`,
            { id: regionId, dates },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            setSent(true);
            setSending(false);

            toast.success("All successfully saved");
          })
          .catch((err) => {
            setErrorMessage(err.response.data);
            toast.error(err.response.data);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <br />
                      Please, upload the .xlsx file of PB IELTS dates:
                      <FormGroup row>
                        <Colxx sm="9">
                          <Input
                            type="file"
                            accept=".xlsx"
                            onChange={async (e) => {
                              const files = e.target.files;
                              if (files) {
                                setCandidatesLoading(true);
                                const wb = read(await files[0].arrayBuffer());
                                const data = utils.sheet_to_json(
                                  wb.Sheets[wb.SheetNames[0]]
                                );
                                console.log(data);
                                let xlsdates = [];
                                data.forEach((one) => {
                                  if (one.Dates) {
                                    one.Dates.split(",").forEach((date) => {
                                      if (date.trim()) {
                                        xlsdates.push({
                                          title: "PB",
                                          date: moment(
                                            `${one.Month} ${date.trim()}, ${
                                              one.Year
                                            }`
                                          ).format("YYYY-MM-DD"),
                                        });
                                      }
                                    });
                                  }
                                });
                                // console.log(xlsdates);
                                setDates(xlsdates);
                                setCandidatesLoading(false);
                              }
                            }}
                          />
                        </Colxx>
                      </FormGroup>
                      {sending && (
                        <span className="btn btn-warning">Uploading...</span>
                      )}
                      {sent && <span className="btn btn-success">DONE!</span>}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
      <div>
        <Button
          color="light"
          className="top-right-button"
          onClick={() => toggle()}
        >
          Close
        </Button>
        {!sent && (
          <Button
            color="primary"
            className="ml-2 top-right-button"
            onClick={handleSubmit}
          >
            Save
          </Button>
        )}
      </div>
      &nbsp;
      <div>{errorMessage}</div>
    </div>
  );
};

export default ImportPbModal;
