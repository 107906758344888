import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";

const AddRegion = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [name, setName] = useState("");
  const [pbIelts, setPbIelts] = useState({ name: "Paper Based", status: true });
  const [pbDates, setPbDates] = useState([]);
  const [cdIelts, setCdIelts] = useState({ name: "CD-IELTS", status: false });
  const [cdDates, setCdDates] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const partnerSwitchStyle = { backgroundColor: "#00FFF6 !important" };

  const handleAddDate = (e, module) => {
    if (module === "pb") {
      setPbDates([{ date: e.target.value, status: true }, ...pbDates]);
    } else {
      setCdDates([{ date: e.target.value, status: true }, ...cdDates]);
    }
  };

  const handleDateChange = (e, index, module, isCheckbox, isPartnerCheck) => {
    if (module === "pb") {
      let tempValue = [...pbDates];
      if (isCheckbox) {
        tempValue[index].status = e;
      } else if (isPartnerCheck) {
        tempValue[index].partnerStatus = e;
      } else {
        tempValue[index].date = e.target.value;
      }
      setPbDates(tempValue);
    } else {
      let tempValue = [...cdDates];
      if (isCheckbox) {
        tempValue[index].status = e;
      } else if (isPartnerCheck) {
        tempValue[index].partnerStatus = e;
      } else {
        tempValue[index].date = e.target.value;
      }
      setCdDates(tempValue);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setErrorMessage("The name of the region must be filled");
    } else {
      let data = {
        name,
        status: pbIelts.status || cdIelts.status,
        partnerStatus: pbIelts.partnerStatus || cdIelts.partnerStatus,
        modules: [
          { ...pbIelts, dates: pbDates },
          { ...cdIelts, dates: cdDates },
        ],
      };
      axios
        .post(`/api/details/regions`, isNew ? data : { id, ...data }, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          if (result.data.success) {
            navigate("/app/regions");
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  const handleDelete = () => {
    axios
      .delete(`/api/details/region/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.data.success) {
          navigate("/app/regions");
        }
      });
  };

  const handleRemovePbDate = (e, index) => {
    let newDates = [...pbDates];
    newDates.splice(index, 1);
    setPbDates(newDates);
  };

  const handleRemoveCdDate = (e, index) => {
    let newDates = [...cdDates];
    newDates.splice(index, 1);
    setCdDates(newDates);
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/details/region/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result;
          setPbIelts({
            ...pbIelts,
            status: data.modules[0].status,
            partnerStatus: data.modules[0].partnerStatus,
          });
          setPbDates(data.modules[0].dates);
          setCdIelts({
            ...cdIelts,
            status: data.modules[1].status ? data.modules[1].status : false,
            partnerStatus: data.modules[1].partnerStatus
              ? data.modules[1].partnerStatus
              : false,
          });
          setCdDates(data.modules[1]?.dates);
          setName(data.name);
          setLoading(false);
        });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{isNew ? "New" : "Update"} Region</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="8">
                        <FormGroup row>
                          <Label sm="3">Region Name</Label>
                          <Colxx sm="9">
                            <Input
                              type="text"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>

              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Row>
                        <Col xs="8">
                          <h2>Paper Based</h2>
                        </Col>
                        <Col xs="4">
                          <Switch
                            className="custom-switch custom-switch-primary-inverse"
                            checked={pbIelts.status}
                            onChange={(value) =>
                              setPbIelts({ ...pbIelts, status: value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="8" className="text-right">
                          <h4>For Partners</h4>
                        </Col>
                        <Col xs="3">
                          <Switch
                            className="custom-switch custom-switch-primary-inverse"
                            checked={pbIelts.partnerStatus}
                            id="partner-switch"
                            onChange={(value) =>
                              setPbIelts({ ...pbIelts, partnerStatus: value })
                            }
                          />
                        </Col>
                        <Col xs="12">
                          <br />
                        </Col>
                      </Row>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup row>
                          <Label sm="4">New Exam Date</Label>
                          <Colxx sm="7">
                            <Input
                              type="text"
                              placeholder="type the date and press Enter"
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleAddDate(e, "pb");
                                }
                              }}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <br />
                      </Col>
                      {pbDates.map((date, index) => (
                        <Col md="6" key={index}>
                          <FormGroup row>
                            <Colxx sm="7">
                              <Input
                                type="text"
                                value={date.date}
                                onChange={(e) =>
                                  handleDateChange(e, index, "pb", false)
                                }
                              />
                            </Colxx>
                            <Switch
                              sm="3"
                              className="custom-switch custom-switch-primary-inverse"
                              checked={date.status}
                              onChange={(e) =>
                                handleDateChange(e, index, "pb", true)
                              }
                            />
                            <Switch
                              sm="3"
                              className="custom-switch custom-switch-primary-inverse ml-1"
                              checked={date.partnerStatus}
                              id="partner-switch"
                              onChange={(e) =>
                                handleDateChange(e, index, "pb", false, true)
                              }
                            />
                            <span
                              className="btn btn-header-light icon-button"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleRemovePbDate(e, index)}
                            >
                              <i className="simple-icon-trash" />
                            </span>
                          </FormGroup>
                        </Col>
                      ))}
                    </Row>
                  </Form>
                </CardBody>
              </Card>

              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Row>
                        <Col xs="8">
                          <h2>CD-IELTS</h2>
                        </Col>
                        <Col xs="4">
                          <Switch
                            className="custom-switch custom-switch-primary-inverse"
                            checked={cdIelts.status}
                            onChange={(value) =>
                              setCdIelts({ ...cdIelts, status: value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="8" className="text-right">
                          <h4>For Partners</h4>
                        </Col>
                        <Col xs="3">
                          <Switch
                            className="custom-switch custom-switch-primary-inverse"
                            checked={cdIelts.partnerStatus}
                            id="partner-switch"
                            onChange={(value) =>
                              setCdIelts({ ...cdIelts, partnerStatus: value })
                            }
                          />
                        </Col>
                        <Col xs="12">
                          <br />
                        </Col>
                      </Row>
                    </Row>
                    <Row hidden={!cdIelts.status && !cdIelts.partnerStatus}>
                      <Col md="6">
                        <FormGroup row>
                          <Label sm="4">New Exam Date</Label>
                          <Colxx sm="7">
                            <Input
                              type="text"
                              placeholder="type the date and press Enter"
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleAddDate(e, "cd");
                                }
                              }}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <br />
                      </Col>
                      {cdDates?.map((date, index) => (
                        <Col md="6" key={index}>
                          <FormGroup row>
                            <Colxx sm="7">
                              <Input
                                type="text"
                                value={date.date}
                                onChange={(e) =>
                                  handleDateChange(e, index, "cb", false)
                                }
                              />
                            </Colxx>
                            <Switch
                              sm="3"
                              className="custom-switch custom-switch-primary-inverse"
                              checked={date.status}
                              onChange={(e) =>
                                handleDateChange(e, index, "cd", true)
                              }
                            />
                            <Switch
                              sm="3"
                              className="custom-switch custom-switch-primary-inverse ml-1"
                              checked={date.partnerStatus}
                              id="partner-switch"
                              onChange={(e) =>
                                handleDateChange(e, index, "cd", false, true)
                              }
                            />
                            <span
                              className="btn btn-header-light icon-button"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleRemoveCdDate(e, index)}
                            >
                              <i className="simple-icon-trash" />
                            </span>
                          </FormGroup>
                        </Col>
                      ))}
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="light"
            className="top-right-button "
            onClick={() => navigate("/app/regions")}
          >
            Cancel
          </Button>
          {!isNew && (
            <Button
              color="danger"
              className="top-right-button ml-2"
              onClick={handleDelete}
            >
              Delete
            </Button>
          )}
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        <div>{errorMessage}</div>
      </div>
    </AppLayout>
  );
};

export default AddRegion;
