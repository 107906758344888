import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import SendNotificationModal from "./sendNotificationModal";
import { dateSlashToDrop } from "utils/stringFunctions";
import ConfirmModal from "./confirmModal";
import SaveBulkCandidates from "../candidates/saveBulkModal";
import SendSmsModal from "./sendSmsModal";
import AddMainVenueModal from "./addMainVenueModal";
import DownloadAttendanceModal from "./downloadAttendanceModal";

const initialValues = {
  name: "",
  status: true,
  slots: [],
};

const EditDate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState(initialValues);

  const [name, setName] = useState("");
  const [status, setStatus] = useState(true);
  const [sessionDate, setSessionDate] = useState(null);

  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useToken();

  const [sendModal, setSendModal] = useState(false);
  const [sendSmsModal, setSendSmsModal] = useState(false);
  const [mainTestModal, setMainTestModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [removeSpeakingModal, setRemoveSpeakingModal] = useState(false);
  const [attendanceModal, setAttendanceModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState(0);
  const [candidates, setCandidates] = useState([]);

  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (name === "") {
      toast.error("The name of the session must be filled");
    } else {
      // let slotIdsArray = [];
      // values.slots.map((slot) => slotIdsArray.push(slot._id));
      let data = {
        name,
        status,
      };
      axios
        .post(
          `/api/speaking/date/${id}`,
          isNew ? { ...data, invigilators: [] } : { id, ...data },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          setSubmitting(false);
          toast.success("Successfully updated");
        })
        .catch((err) => {
          toast.error(err.response.data);
        });
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();

    let data = {
      mainTestDate: sessionDate,
      search: search,
      withExaminer: true,
      region: values.region,
      venue: values.venue,
    };
    axios
      .post(`/api/candidates-maintest`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setCandidates(result.data.candidates);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`/api/speaking/slot/${selectedId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.status === 200) {
          toast.success("Successfully deleted");
          navigate(0);
        }
      });
  };

  const handleRemoveSpeaking = () => {
    axios
      .post(
        `/api/candidate-remove-speaking/${selectedId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          toast.success("Successfully removed");
          navigate(0);
        }
      });
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/speaking/date/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result;
          // console.log(data);
          setName(data.name);
          setStatus(data.status);
          setSessionDate(data.mainTestDate);
          setLoading(false);
          setValues(data);
        });
  }, [id, isNew]);

  const handleClickSlot = (id, orderNumber) => {
    navigate(`/app/speaking/slot/${id}`);
    // setSlotOrder(orderNumber);
    // setSlotId(id);
    // toggleEditModal();
  };

  if (importModal || sendModal || mainTestModal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const toggleImportModal = () => {
    setImportModal(!importModal);
  };

  const toggleSendModal = () => {
    setSendModal(!sendModal);
  };

  const toggleSendSmsModal = () => {
    setSendSmsModal(!sendSmsModal);
  };

  const toggleSetMainTestModal = () => {
    setMainTestModal(!mainTestModal);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const toggleRemoveSpeakingModal = () => {
    setRemoveSpeakingModal(!removeSpeakingModal);
  };

  const toggleAttendanceModal = () => {
    setAttendanceModal(!attendanceModal);
  };

  const getBookedWindows = (windows) => {
    let booked = 0;
    let all = 0;
    windows.forEach((window) => {
      if (!window.deleted) {
        if (!window.available) {
          booked += 1;
        }
        all += 1;
      }
    });
    return `${booked}/${all}`;
  };

  const getBookedWindowsAll = (slots) => {
    let booked = 0;
    let all = 0;
    slots.forEach((slot) => {
      slot.windows.forEach((window) => {
        if (!window.deleted) {
          if (!window.available) {
            booked += 1;
          }
          all += 1;
        }
      });
    });

    return `${booked}/${all}`;
  };

  const resolveAfterSec = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
      }, 1000);
    });
  };

  const handleDownload = async (type) => {
    setDownloadLoading(true);
    // eslint-disable-next-line default-case
    switch (type) {
      case "single":
      case "multi":
        for (let i = 0; i < values.slots.length; i++) {
          const slot = values.slots[i];
          axios
            .post(
              `/api/speaking-download-${type}`,
              {
                mainTestDate: sessionDate,
                examinerNumber: slot.examinerId.staffId,
                sessionDate: dateSlashToDrop(slot.speakingDate),
                venue: values.venue,
                region: values.region,
              },
              {
                responseType: "blob",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((result) => {
              const url = window.URL.createObjectURL(new Blob([result.data]));
              const link = document.createElement("a");
              link.href = url;
              link.download = `${type}-${
                slot.examinerId.staffId + "-" + slot.speakingDate
              }.pdf`; //or any other extension
              document.body.appendChild(link);
              link.click();
            })
            .catch((err) => {
              toast.error(err.response.data);
            });

          const tempWait = await resolveAfterSec();
        }
        toast.success("The files are downloaded successfully.");
        setDownloadLoading(false);
        break;
      case "excel":
        axios
          .post(
            `/api/speaking-download-${type}`,
            {
              mainTestDate: sessionDate,
              venue: values.venue,
              region: values.region,
            },
            {
              responseType: "blob",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement("a");
            link.href = url;
            link.download = `Speaking-${sessionDate}.xlsx`; //or any other extension
            document.body.appendChild(link);
            link.click();
            toast.success("The file is downloaded successfully.");
            setDownloadLoading(false);
          })
          .catch((err) => {
            toast.error(err.response.data);
            setDownloadLoading(false);
          });
        break;
      case "csv":
        axios
          .post(
            `/api/speaking-download-${type}`,
            {
              mainTestDate: sessionDate,
              venue: values.venue,
              region: values.region,
            },
            {
              responseType: "blob",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement("a");
            link.href = url;
            link.download = `Speaking-${sessionDate}.csv`; //or any other extension
            document.body.appendChild(link);
            link.click();
            toast.success("The file is downloaded successfully.");
            setDownloadLoading(false);
          })
          .catch((err) => {
            toast.error(err.response.data);
            setDownloadLoading(false);
          });
        break;
    }
  };

  const handleUpdatePinpp = async (type) => {
    setDownloadLoading(true);
    // eslint-disable-next-line default-case

    axios
      .post(
        `/api/speaking-update-pinpp`,
        {
          mainTestDate: sessionDate,
          venue: values.venue,
          region: values.region,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        toast.success("Candidates are updated");
        setDownloadLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setDownloadLoading(false);
      });
  };

  const handleUpdateAll = () => {
    setUpdating(true);
    axios
      .post(
        `/api/speaking/update-all-slots-new`,
        { sessionId: id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          toast.success("All the slots are updated.");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => toast.error("Something went wrong - 500"));
    setUpdating(false);
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{isNew ? "New" : "Update"} Session</h1>
              <div
                className="text-zero top-right-button-container"
                style={{ display: "flex" }}
              >
                <div>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      color="primary"
                      // className="btn-xs"
                      outline
                      disabled={downloadLoading}
                    >
                      Actions
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem onClick={toggleImportModal}>
                        Import Candidates
                      </DropdownItem>
                      <DropdownItem onClick={() => handleDownload("excel")}>
                        {"Candidates Excel"}
                      </DropdownItem>
                      <DropdownItem onClick={() => handleDownload("csv")}>
                        {"Candidates CSV"}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleUpdateAll()}
                        disabled={updating}
                      >
                        {updating ? "Updating..." : "Update All"}
                      </DropdownItem>
                      <DropdownItem onClick={() => handleDownload("single")}>
                        {"Examiner's Report - Single"}
                      </DropdownItem>
                      <DropdownItem onClick={() => handleDownload("multi")}>
                        {"Examiner's Report - Multi"}
                      </DropdownItem>
                      <DropdownItem onClick={toggleSetMainTestModal}>
                        {"Set Main Test Venue"}
                      </DropdownItem>
                      <DropdownItem onClick={toggleSendSmsModal}>
                        {"Send SMS"}
                      </DropdownItem>
                      <DropdownItem onClick={toggleAttendanceModal}>
                        Attendance Report
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          navigate(`/app/speaking/invigilators/${id}`)
                        }
                      >
                        Invigilators
                      </DropdownItem>
                      <DropdownItem onClick={() => handleUpdatePinpp()}>
                        Update PINPP
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <Button
                  color="primary"
                  className="top-right-button ml-2"
                  size="sm"
                  onClick={toggleSendModal}
                >
                  Send notifications
                </Button>
              </div>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <FormGroup row>
                          <Label sm="2">Session Name</Label>
                          <Colxx sm="4">
                            <Input
                              type="text"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Colxx>
                          <Colxx xs="4">
                            <Switch
                              className="custom-switch custom-switch-primary-inverse"
                              checked={status}
                              onChange={(value) => setStatus(value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup row>
                          <Label sm="4">Main Test Date</Label>
                          <Colxx sm="8">
                            <Input
                              type="text"
                              name="sessionDate"
                              value={sessionDate}
                              disabled
                              onChange={(e) => setSessionDate(e.target.value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>

                  <div className="d-flex justify-content-between align-items-center">
                    <div></div>
                    <div>
                      <Button
                        color="light"
                        className="top-right-button"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                      {/* {!isNew && (
            <Button
              color="primary"
              className="top-right-button ml-2"
              onClick={handleDeleteUser}
            >
              Delete
            </Button>
          )} */}
                      <Button
                        color="primary"
                        className="top-right-button ml-2"
                        disabled={submitting}
                        onClick={handleSubmit}
                      >
                        {isNew ? "Add" : "Update"}
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Separator className="mt-2" />
              <Row className="mt-3 ml-2">
                <Col sm="11">
                  <h2>
                    Slots{" "}
                    <span>
                      <i
                        className="simple-icon-plus ml-2 clickable"
                        onClick={() => navigate(`/app/speaking/add/${id}`)}
                      />
                    </span>
                  </h2>
                </Col>
                <Col sm="1">
                  <h3>{getBookedWindowsAll(values?.slots)}</h3>
                </Col>
                {values.slots.map((slot, index) => (
                  <Colxx xxs="12" key={index} className="mb-3">
                    <Card className="d-flex flex-row">
                      <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                        <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                          <span
                            onClick={() => handleClickSlot(slot._id, index)}
                            className="w-40 w-sm-100 clickable"
                          >
                            <p className="list-item-heading mb-1 truncate">
                              {slot?.examinerId.name} (
                              {slot?.examinerId.staffId})
                            </p>
                          </span>
                          <p className="mb-1 text-muted w-25 w-sm-100">
                            {slot?.speakingDate}
                          </p>
                          <p className="mb-1 text-muted w-25 w-sm-100">
                            {slot?.venueId.name} ({slot?.venueId.placeId})
                          </p>
                          <p className="mb-1 text-muted text-small w-15 w-sm-100">
                            {getBookedWindows(slot?.windows)}
                          </p>
                          <p className="mb-1 text-muted text-small w-15 w-sm-100">
                            {slot.vcs ? "VCS" : "F2F"}
                          </p>
                          <p className="mb-1 text-muted text-small w-5 w-sm-100">
                            <i
                              className="simple-icon-trash ml-2 clickable"
                              onClick={() => {
                                setSelectedId(slot._id);
                                toggleDeleteModal();
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </Card>
                  </Colxx>
                ))}
              </Row>

              <Separator className="mt-2 mb-5" />
              <Row>
                <Colxx xxs="12" className="mb-4">
                  <Card className="mb-4">
                    <CardBody>
                      <Row>
                        <Col md="8">
                          <FormGroup row>
                            <Label sm="2">Search Word</Label>
                            <Colxx sm="6">
                              <Input
                                type="text"
                                name="name"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </Colxx>
                            <Colxx xs="4">
                              <Button
                                color="primary"
                                className="top-right-button ml-2"
                                onClick={handleSearch}
                              >
                                Search
                              </Button>
                            </Colxx>
                          </FormGroup>
                        </Col>
                      </Row>
                      {candidates.map((candidate, index) => (
                        <Colxx xxs="12" key={index} className="mb-3">
                          <Card className="">
                            <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                              <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                <p className="list-item-heading mb-1 truncate">
                                  {candidate?.GIVEN_NAME}{" "}
                                  {candidate?.FAMILY_NAME}
                                </p>
                                <p className="mb-1 text-muted w-15 w-sm-100">
                                  {candidate?.CANDIDATE_NUMBER}
                                </p>
                                <p className="mb-1 text-muted w-15 w-sm-100">
                                  {candidate?.EXAMINER_NAME}
                                </p>
                                <p className="mb-1 text-muted w-15 w-sm-100">
                                  {candidate?.ORAL_SESSION_DATE}
                                </p>
                                <p className="mb-1 text-muted w-15 w-sm-100">
                                  {candidate?.START_TIME}
                                </p>
                              </div>
                            </div>
                            <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                              <div className="ml-4 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                <p className="list-item-heading mb-1 truncate">
                                  Main Test Details:
                                </p>
                                <p className="ml-2 mb-1 text-muted">
                                  {candidate?.mainTestVenue} |
                                </p>
                                <p className="ml-2 mb-1 text-muted">
                                  {candidate?.mainTestAddress} |
                                </p>
                                <p className="ml-2 mb-1 text-muted">
                                  {candidate?.mainTestRoom} |
                                </p>
                                <p className="ml-2 mb-1 text-muted">
                                  {candidate?.mainTestLocation}
                                </p>
                              </div>
                            </div>
                            <p>
                              {candidate?.notes}
                              <Button
                                size="xs"
                                className="ml-2"
                                outline
                                color="primary"
                                onClick={() => {
                                  setSelectedId(candidate._id);
                                  toggleRemoveSpeakingModal();
                                }}
                              >
                                Remove Speaking
                              </Button>
                              <Button
                                size="xs"
                                className="ml-2"
                                outline
                                color="primary"
                                onClick={() => {
                                  window.open(
                                    `/app/speaking/edit-candidate/${candidate._id}`,
                                    "_blank",
                                    "noreferrer"
                                  );
                                }}
                              >
                                Edit
                              </Button>
                            </p>
                          </Card>
                        </Colxx>
                      ))}
                    </CardBody>
                  </Card>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
        {importModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <SaveBulkCandidates
                toggle={toggleImportModal}
                sessionDate={sessionDate}
                dateId={id}
                region={values.region}
                venue={values.venue}
              />
            </div>
          </div>
        )}
        {sendModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content-bigger">
              <SendNotificationModal
                toggle={toggleSendModal}
                sessionDate={sessionDate}
                region={values.region}
                venue={values.venue}
              />
            </div>
          </div>
        )}

        {sendSmsModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content-bigger">
              <SendSmsModal
                toggle={toggleSendSmsModal}
                sessionDate={sessionDate}
                region={values.region}
                venue={values.venue}
              />
            </div>
          </div>
        )}
        {mainTestModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content-bigger">
              <AddMainVenueModal
                toggle={toggleSetMainTestModal}
                sessionDate={sessionDate}
                region={values.region}
                venue={values.venue}
              />
            </div>
          </div>
        )}
        {deleteModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content-bigger">
              <ConfirmModal toggle={toggleDeleteModal} action={handleDelete} />
            </div>
          </div>
        )}

        {removeSpeakingModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content-bigger">
              <ConfirmModal
                toggle={toggleRemoveSpeakingModal}
                action={handleRemoveSpeaking}
              />
            </div>
          </div>
        )}
        {attendanceModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content-bigger">
              <DownloadAttendanceModal
                toggle={toggleAttendanceModal}
                sessionDate={sessionDate}
                region={values.region}
                venue={values.venue}
              />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default EditDate;
