import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ConfirmModal = ({ modal, setModal, title, body, action }) => {
  return (
    <>
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader>{title}</ModalHeader>
        {body && <ModalBody>{body}</ModalBody>}

        <ModalFooter>
          <Button color="light" onClick={() => setModal(false)}>
            No
          </Button>
          <Button
            color="primary ml-2"
            onClick={() => {
              action();
              setModal(false);
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConfirmModal;
