import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { CKEditor } from "ckeditor4-react";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";
import Select from "react-select";
import { reactSelectChange } from "utils/reactSelectChange";

const initialBody = `<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Dear #GIVEN_NAME# #FAMILY_NAME# </span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Please enter official edu-action speaking test portal <a href="https://speaking.edu-action.uk">speaking.edu-action.uk</a> and choose available test date and time until 3pm today. </span></span></p>

<p>&nbsp;</p>

<p><span style="font-family:Calibri,sans-serif; font-size:11pt">Use your passport number as a login (sample AA132132) and generated password to enter the system . Please be noted, that you can choose speaking slot only once.</span></p>

<p><strong>YOUR PASSWORD: #sw#</strong></p>

<p>&nbsp;</p>

<p><em><span style="font-family:Calibri,sans-serif; font-size:11pt">After you choose your speaking date and time, please wait for sms and email which will be sent this Friday for confirmation. Please be noted that the speaking time and date you choose might change.</span></em></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="color:red">Please Note: This email has been sent from a mailbox which does not accept replies. If necessary, please reply to contact detail below.</span></strong></span></span><br />
<span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong>&nbsp;</strong><a href="mailto:ielts@edu-action.uz"><strong>ielts@edu-action.uz</strong></a></span></span></p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Best Regards, </span></span></p>

<table cellspacing="0" style="border-collapse:collapse">
  <tbody>
    <tr>
      <td style="background-color:white; height:128px; vertical-align:top; width:246px">
      <p style="text-align:right"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:14.0pt"><span style="font-family:&quot;Verdana&quot;,sans-serif"><span style="color:black">IELTS Adminstrator</span></span></span></strong><br />
      <span style="font-size:10.0pt"><span style="font-family:&quot;Verdana&quot;,sans-serif"><span style="color:black">Edu-Action Test Center<br />
      IELTS by IDP Australia</span></span></span></span></span><br />
      <span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:black">ielts@edu-action.uz</span></span></span></p>
      </td>
      <td style="background-color:white; height:128px; vertical-align:top; width:364px">
      <p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:black"></span></span></span></p>

      <p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:10.0pt"><span style="font-family:&quot;Verdana&quot;,sans-serif"><span style="color:black">Bunyodkor street 2, Tashkent Uzbekistan 100015</span></span></span></span></span><br />
      <span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:10.0pt"><span style="font-family:&quot;Verdana&quot;,sans-serif"><span style="color:black">(CIU novza branch, 4th floor)</span></span></span></span></span><br />
      <span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:black"><a href="http://www.exams.uz/"><span style="font-size:10.0pt"><span style="font-family:&quot;Verdana&quot;,sans-serif"><span style="color:#bd272d">www.exams.uz</span></span></span></a></span></span></span></p>
      </td>
    </tr>
  </tbody>
</table>

<p>&nbsp;</p>`;

const SendNotificationModal = (props) => {
  const { toggle, sessionDate, region, venue } = props;
  const user = useUser();

  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);

  const editorConfig = {
    toolbar: [
      ["Undo", "Redo"],
      ["Format", "Font", "FontSize"],
      ["TextColor", "BGColor"],
      ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
      [
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "RemoveFormat",
        "CopyFormatting",
      ],
      ["Link", "Unlink"],
      [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "-",
        "Blockquote",
      ],
      ["Image", "Table"],
      ["Scayt"],
    ],
    extraPlugins: "justify,format,font,colorbutton",
  };

  const [showEditor, setShowEditor] = useState(false);
  const [subject, setSubject] = useState(
    "Choose your IELTS Speaking date and time"
  );
  const [body, setBody] = useState(initialBody);
  const [attachments, setAttachments] = useState([]);

  const handleSendNotifications = () => {
    // console.log({
    //   mainTestDate: sessionDate,
    //   venue,
    //   region,
    //   content: body,
    //   subject,
    //   attachments,
    // });
    setSending(true);
    axios
      .post(
        `/api/speaking/send-notifications`,
        {
          mainTestDate: sessionDate,
          venue,
          region,
          content: body,
          subject,
          attachments,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setSending(false);
          toast.success("The emails have been sent");
          toggle();
          setSuccess(true);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    axios
      .get(`/api/details/info/64076afa23af4c3fde565c69`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setBody(result?.data?.info);
        setShowEditor(true);
      });
  }, []);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>Send Notification</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>

      <Row>
        <Colxx xxs="12" className="mb-4">
          <Card className="mb-4">
            <CardBody>
              <Form>
                <Row>
                  <Col md="6">
                    <p>
                      Region: {region} &nbsp;&nbsp; | &nbsp;&nbsp; Venue:{" "}
                      {venue}
                    </p>
                  </Col>
                  <Col md="12">
                    <>
                      <FormGroup>
                        <h2>Email Subject</h2>
                        <Input
                          type="text"
                          name="subject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                      </FormGroup>
                      <h2>Email Content</h2>
                      {showEditor && (
                        <CKEditor
                          initData={body}
                          onChange={(event) => setBody(event.editor.getData())}
                          config={editorConfig}
                        />
                      )}
                      <FormGroup>
                        <h2>Attachments</h2>
                        <Select
                          options={[
                            {
                              label: "Consent Form",
                              value: "Consent-Form.pdf",
                            },
                          ]}
                          onChange={(e) => setAttachments(reactSelectChange(e))}
                          isMulti
                        />
                      </FormGroup>
                    </>
                  </Col>
                </Row>
              </Form>
              <div className="mt-3">
                <Button
                  color="primary"
                  className="top-right-button"
                  onClick={toggle}
                >
                  Close
                </Button>
                <Button
                  color={success ? "success" : "primary"}
                  className="top-right-button ml-2"
                  onClick={handleSendNotifications}
                  disabled={success || sending}
                >
                  {success ? "Done" : `Send`}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Colxx>
      </Row>
    </div>
  );
};

export default SendNotificationModal;
