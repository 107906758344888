import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { toast } from "react-toastify";

import { Colxx, Separator } from "components/common/CustomBootstrap";
import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";

const SpeakingRegionsList = () => {
  const [data, setData] = useState([
    { name: "Tashkent", region: "Tashkent" },
    { name: "CD", region: "cd" },
    { name: "Regions", region: "regions" },
  ]);

  const navigate = useNavigate();

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="ml-2 d-block d-flex justify-content-between">
              <div className="mb-2">
                <h1>Speaking Regions List</h1>
                <div className="text-zero top-right-button-container"></div>
              </div>
            </div>
            <Separator className="mb-1" />
            <div className="ml-2 d-block d-flex justify-content-between">
              <div>
                <Button
                  color="primary"
                  className="top-right-button align-left mb-2"
                  onClick={() => navigate("examiners")}
                >
                  Examiners
                </Button>
                <Button
                  color="primary"
                  className="top-right-button align-left ml-2 mb-2"
                  onClick={() => navigate("venues")}
                >
                  Venues
                </Button>
                <Button
                  color="primary"
                  className="top-right-button align-left mb-2 ml-2"
                  onClick={() => navigate("/app/rooms")}
                >
                  Rooms
                </Button>
              </div>
            </div>
          </Colxx>
        </Row>
        <Row>
          {data.map((date) => (
            <Colxx xxs="12" key={date._id} className="mb-3">
              <Card className="d-flex flex-row">
                <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                  <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                    <NavLink
                      to={`date?region=${date.region}`}
                      className="w-40 w-sm-100"
                    >
                      <p className="list-item-heading mb-1 truncate">
                        {date.name}
                      </p>
                    </NavLink>
                  </div>
                </div>
              </Card>
            </Colxx>
          ))}
        </Row>
      </div>
    </AppLayout>
  );
};

export default SpeakingRegionsList;
