import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";
import { useNavigate } from "react-router-dom";

const AddInvModal = (props) => {
  const [invigilators, setInvigilators] = useState([]);
  const [selectedInvigilators, setSelectedInvigilators] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const user = useUser();
  const navigate = useNavigate();

  const [token, setToken] = useToken();

  // Fetch invigilators based on search term and page
  const fetchInvigilators = async () => {
    setLoading(true);

    // Implement your API call to fetch invigilators based on searchTerm and page
    // Replace the following line with your actual API endpoint
    const response = await axios.get(
      `/api/invigilators-to-date?search=${searchTerm}&page=${page}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setInvigilators(response.data);
    setLoading(false);
  };

  // Load initial invigilators on mount
  useEffect(() => {
    fetchInvigilators();
  }, [searchTerm, page]);

  // Function to handle checkbox change
  const handleCheckboxChange = (invigilatorId) => {
    setSelectedInvigilators((prevSelected) =>
      prevSelected.includes(invigilatorId)
        ? prevSelected.filter((id) => id !== invigilatorId)
        : [...prevSelected, invigilatorId]
    );
  };

  // Function to handle modal close
  const handleClose = async () => {
    // Pass the selected invigilators array to the parent component or perform any other action
    // onClose(selectedInvigilators);

    console.log(selectedInvigilators);
    try {
      let result = await axios.post(
        `/api/add-invigilators-to-date`,
        { documentId: props.dateId, invigilatorIds: selectedInvigilators },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (result.status === 200) {
        toast.success("Successfully added invigilators to date");
        navigate(0);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>Add Invigilators</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>

      <Row>
        <Colxx xxs="12" className="mb-4">
          <Card className="mb-4">
            <CardBody>
              <Row className="text-left">
                <Col md="6">
                  <Row>
                    <FormGroup>
                      <Input
                        type="text"
                        name="searchTerm"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </FormGroup>
                    <Button
                      color="primary"
                      size="xs"
                      className="ml-2 mt-1"
                      style={{ height: "fit-content" }}
                      onClick={fetchInvigilators}
                    >
                      Search
                    </Button>
                  </Row>
                  {invigilators.map((invigilator) => (
                    <div key={invigilator._id}>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedInvigilators.includes(
                            invigilator._id
                          )}
                          onChange={() => handleCheckboxChange(invigilator._id)}
                        />{" "}
                        {invigilator.fullName}
                      </label>
                    </div>
                  ))}

                  {loading && <p>Loading...</p>}
                </Col>
              </Row>
              <Row>
                <Button color="light" className="mr-2" onClick={props.toggle}>
                  Close
                </Button>
                <Button color="primary" onClick={handleClose}>
                  Add Invigilators
                </Button>
              </Row>
            </CardBody>
          </Card>
        </Colxx>
      </Row>
    </div>
  );
};

export default AddInvModal;
