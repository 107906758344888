import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { QrScanner } from "@yudiel/react-qr-scanner";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";

const EditInspect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();

  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const [qrOn, setQrOn] = useState(false);

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.password === "") {
      delete values.password;
    }
    let today = new Date();
    let data = {
      payment_id: id,
      ...values,
      pinflEditor: user.username,
      pinflUpdated: new Date(today.setHours(today.getHours() + 5)),
    };

    axios
      .post(`https://new.exams.uz/api/payment/update`, data)
      .then((result) => {
        // console.log(result);
        if (result.data.data.id) {
          navigate("/app/inspect");
        } else {
          setErrorMessage("Something went wrong");
        }
      })
      .catch((err) => {
        // console.log(err.response.data);
        setErrorMessage(Object.values(err.response.data.errors)[0]);
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://new.exams.uz/api/payment/detail?id=${id}`)
      .then((result) => {
        let { data } = result.data;
        setValues(data);
        setLoading(false);
      });
  }, [id]);

  const handleScan = (data) => {
    if (data.length > 14) {
      setValues({ ...values, pinfl: data.slice(data.length - 14) });
      setQrOn(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Update Details</h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form className="text-left">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Name</Label>
                          <Input
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Surname</Label>
                          <Input
                            type="text"
                            name="surname"
                            value={values.surname}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Passport</Label>
                          <Input
                            type="text"
                            name="passport"
                            value={values.passport}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Exam Date</Label>
                          <Input
                            type="text"
                            name="exam_date"
                            value={values.exam_date}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>PINFL</Label>
                          <Input
                            type="text"
                            name="pinfl"
                            value={values.pinfl}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <div className="text-left mt-3 mb-2">
                          <Button
                            color="primary"
                            onClick={() => setQrOn(!qrOn)}
                          >
                            QR Scan
                          </Button>
                        </div>

                        {qrOn && (
                          <QrScanner
                            onDecode={(result) =>
                              handleScan(result.split("<")[0])
                            }
                            onError={(error) => handleError(error?.message)}
                          />
                        )}
                      </Col>
                      <Col md="12" className="text-left">
                        <Separator className="mb-5" />
                        <h4>
                          <strong>Payment Details:</strong> {values.amount_paid}{" "}
                          paid for {values.paid_for} by {values.payment_type}.
                          Processed by: {values.prosecced_by}{" "}
                        </h4>
                      </Col>
                    </Row>
                  </Form>
                  <div className="mt-3">
                    <Button
                      color="light"
                      className="top-right-button"
                      onClick={() => navigate("/app/inspect")}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className="top-right-button ml-2"
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  </div>
                  <div>{errorMessage}</div>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
      </div>
    </AppLayout>
  );
};

export default EditInspect;
