export const currencyFormatter = (value = "", symbol) => {
  if (value) {
    // console.log("value", value);
    return `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
      symbol === "" ? symbol : ` ${symbol}`
    }`;
  } else {
    return null;
  }
};
