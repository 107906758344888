/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap"; //
import DatatablePagination from "components/DatatablePagination";
import AppLayout from "layout/AppLayout";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { Link, NavLink, useNavigate } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} className="r-table table text-left">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th key={`th_${columnIndex}`}>
                  {column.render("Header")}
                  <span />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    key={`td_${cellIndex}`}
                    {...cell.getCellProps({
                      className: cell.column.cellClass,
                    })}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <DatatablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[4, 10, 20, 30, 40, 50]}
        showPageSizeOptions={false}
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={pageCount}
      />
      <p>Total Enquiries: {data.length}</p>
    </>
  );
}

const EmailsList = () => {
  const cols = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "receiverName",
        cellClass: "text-muted w-10",
        Cell: (props) => (
          <>
            <NavLink to={`/app/emails/email/${props.row.original._id}`}>
              {props.value}
            </NavLink>
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Email",
        accessor: "receiverEmail",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{props.value}</>,
        sortType: "basic",
      },
      {
        Header: "Date",
        accessor: "createdAt",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{moment(props.value).format("DD/MM/YYYY")}</>,
        sortType: "basic",
      },
      {
        Header: "Status",
        accessor: "isSent",
        cellClass: "text-muted w-10",
        Cell: (props) => (
          <>
            {props.value ? (
              <span className="btn btn-success py-1 px-3">Sent</span>
            ) : (
              <span className="btn btn-danger py-1 px-3">Error</span>
            )}
          </>
        ),
        sortType: "basic",
      },
    ],
    []
  );
  const [token, setToken] = useToken();
  const [emailsList, setEmailsList] = useState([]);
  const [searchValues, setSearchValues] = useState({});
  const [showData, setShowData] = useState(false);
  const navigate = useNavigate();

  const handleSearchValueChange = (e) => {
    setSearchValues({ ...searchValues, [e.target.name]: e.target.value });
  };

  const handleSearchSubmit = () => {
    axios
      .post("/api/emails", searchValues, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setEmailsList(result.data);
        setShowData(true);
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Card className="h-100 m-4">
          <CardBody>
            <CardTitle>
              Emails{" "}
              <Button
                color="secondary"
                className="top-right-button ml-3"
                onClick={() => navigate("/app/emails/send")}
              >
                Send
              </Button>
            </CardTitle>
            <Row className="text-left">
              <Col md="3">
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    name="receiverName"
                    value={searchValues.receiverName}
                    onChange={handleSearchValueChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="text"
                    name="receiverEmail"
                    value={searchValues.receiverEmail}
                    onChange={handleSearchValueChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3" className="my-auto">
                <Button color="primary" onClick={handleSearchSubmit}>
                  Search
                </Button>
              </Col>
            </Row>
            <Table columns={cols} data={emailsList} />
          </CardBody>
        </Card>
      </div>
    </AppLayout>
  );
};

export default EmailsList;
