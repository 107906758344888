import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import { read, utils } from "xlsx";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import { useToken } from "auth/useToken";
import { toast } from "react-toastify";

const SaveCandidates = ({ toggle }) => {
  const navigate = useNavigate();

  const [candidates, setCandidates] = useState([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [file, setFile] = useState(null);
  const [fails, setFails] = useState([]);
  const [token, setToken] = useToken();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      if (errorMessage === "") {
        axios
          .post(
            `/api/partners/upload-candidates`,
            { candidates },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            setSent(true);
            setSending(false);
            if (result.data.fails.length > 0) {
              toast.warning("Some not saved");
              setFails(result.data.fails);
            } else {
              toast.success("All successfully saved");
            }
          })
          .catch((err) => {
            setErrorMessage(err.response.data);
            toast.error(err.response.data);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <br />
                      Please, upload the .xlsx file of candidates:
                      <FormGroup row>
                        <Colxx sm="9">
                          <Input
                            type="file"
                            accept=".xlsx"
                            onChange={async (e) => {
                              const files = e.target.files;
                              if (files) {
                                setCandidatesLoading(true);
                                const wb = read(await files[0].arrayBuffer());
                                const data = utils.sheet_to_json(
                                  wb.Sheets[wb.SheetNames[0]]
                                );
                                console.log(data);
                                const jsonData = JSON.stringify(data, null, 2);
                                const blob = new Blob([jsonData], {
                                  type: "application/json",
                                });
                                const url = URL.createObjectURL(blob);

                                const a = document.createElement("a");
                                a.href = url;
                                a.download = "data.json";
                                document.body.appendChild(a);
                                a.click();

                                document.body.removeChild(a);
                                URL.revokeObjectURL(url);
                                setCandidates(
                                  data.filter(
                                    (one) => one.BookingMethod !== "Candidate"
                                  )
                                );
                                setCandidatesLoading(false);
                              }
                            }}
                          />
                        </Colxx>
                      </FormGroup>
                      {/* <>{JSON.stringify(candidates)}</> */}
                      {candidatesLoading ? (
                        <p>Loading...</p>
                      ) : (
                        <p>The number of candidates: {candidates.length}</p>
                      )}
                      {sending && (
                        <span className="btn btn-warning">Uploading...</span>
                      )}
                      {sent && <span className="btn btn-success">DONE!</span>}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
      <Row>
        {fails.length > 0 &&
          fails.map((user) => (
            <Colxx xxs="12" key={user._id} className="mb-3">
              <Card className="d-flex flex-row">
                <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                  <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                    <span className="w-30 w-sm-100 clickable">
                      <p className="list-item-heading mb-1 truncate">
                        {user.Candidate}
                      </p>
                    </span>
                    <span className="w-15 w-sm-50 clickable">
                      <p className="mb-1 text-small">{user.IDNo}</p>
                    </span>
                    <p className="mb-1 text-muted text-small w-15 w-sm-50">
                      {user.BookingMethod}
                    </p>
                    <p className="mb-1 text-muted text-small w-15 w-sm-40">
                      Exam: {user.SessionDate}
                    </p>
                    <p className="mb-1 text-muted text-small w-15 w-sm-40">
                      {user.Module}
                    </p>
                  </div>
                </div>
              </Card>
            </Colxx>
          ))}
      </Row>
      <div>
        <Button
          color="light"
          className="top-right-button"
          onClick={() => toggle()}
        >
          Close
        </Button>
        {!sent && (
          <Button
            color="primary"
            className="ml-2 top-right-button"
            onClick={handleSubmit}
          >
            Save
          </Button>
        )}
      </div>
      &nbsp;
      <div>{errorMessage}</div>
    </div>
  );
};

export default SaveCandidates;
