import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";

const DetailsList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useToken();

  useEffect(() => {
    let isMounted = true;
    axios
      .get("/api/details/info", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          // console.log(result.data);
          setData(result.data);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (word) => {
    ///
    axios
      .get(`/api/details/info?search=${word}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log(result.data.data);
        setData(result.data);
        setLoading(false);
      });
  };
  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="d-flex justify-content-between">
              <div className="mb-1">
                <h1>Details List</h1>
              </div>
              <div className="mb-1">
                <div className="d-block d-md-inline-block pt-1">
                  <div className="search-sm d-inline-block mb-1 ">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder={"Search"}
                      onKeyPress={(e) => onSearchKey(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Separator className="mb-2" />
          </Colxx>
        </Row>
        <Row>
          <Button
            color="primary"
            className="top-right-button mb-2 ml-auto mr-3"
            onClick={() => navigate("batch-header")}
          >
            Batch Header
          </Button>
          <div className="loading" hidden={!loading} />
          {!loading && data.length === 0 ? (
            <p>List is empty</p>
          ) : (
            data.map((info) => (
              <Colxx xxs="12" key={info._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="text-left card-body align-self-left d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <NavLink to={`${info._id}`} className="w-40 w-sm-100">
                        <p className="list-item-heading mb-1 truncate">
                          {info.title}
                        </p>
                      </NavLink>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
        </Row>
      </div>
    </AppLayout>
  );
};

export default DetailsList;
