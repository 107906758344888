import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Card, CardBody, Button, Form, Col, Input } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import parse from "html-react-parser";
import moment from "moment";

const EmailView = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [edited, setEdited] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [emailValue, setEmailValue] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/email/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let { data } = result;
        // console.log(data);
        setData(data);
        setEmailValue(data.receiverEmail);
        setLoading(false);
      });
  }, [id]);

  const handleUpdate = () => {
    setWaiting(true);
    axios
      .post(
        `/api/email/${id}`,
        { receiverEmail: emailValue },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setWaiting(false);
          setEdited(false);
        } else {
          console.log(result.data);
        }
      });
  };
  const handleSendEmail = () => {
    setWaiting(true);
    axios
      .get(`/api/resend-email/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.status === 200) {
          setWaiting(false);
        } else {
          console.log(result.data);
        }
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Email Details</h1>
              <div className="text-zero top-right-button-container">
                <Button
                  color="primary"
                  className="top-right-button mb-3"
                  onClick={() => (edited ? handleUpdate() : handleSendEmail())}
                >
                  {edited ? "Update" : "Resend Email"}
                </Button>
              </div>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Row>
                    <Col md="4" className="my-auto">
                      <p>
                        <strong>Name: </strong>
                        {data.receiverName}
                      </p>
                    </Col>
                    <Col md="4" className="my-auto">
                      <p>
                        <strong>Date: </strong>
                        {moment(data?.updatedAt).format("DD/MM/YYYY")}
                      </p>
                    </Col>
                    <Col md="1" className="text-right mt-2">
                      <p>
                        <strong>Email: </strong>
                      </p>
                    </Col>
                    <Col md="3">
                      <Input
                        type="text"
                        value={emailValue}
                        onChange={(e) => {
                          setEmailValue(e.target.value);
                          setEdited(true);
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Row className="text-left pl-5">
                        <Col xs="12">
                          <h3>Subject: {data.subject}</h3>
                          <Separator className="mb-5" />
                        </Col>
                        {!loading && <Col xs="12">{parse(data.body)}</Col>}
                      </Row>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="primary"
            className="top-right-button"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
    </AppLayout>
  );
};

export default EmailView;
