import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { CKEditor } from "ckeditor4-react";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import data from "constants/menu";
import Papa from "papaparse";
import Select from "react-select";
import { reactSelectChange } from "utils/reactSelectChange";

const SendEmail = () => {
  const ref = useRef(null);
  const navigate = useNavigate();

  const [showEditor, setShowEditor] = useState(false);
  const [subject, setSubject] = useState(
    "IELTS SPEAKING AND MAIN TEST DATE AND TIME"
  );
  const [body, setBody] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [file, setFile] = useState(null);
  const [token, setToken] = useToken();

  const editorConfig = {
    toolbar: [
      ["Undo", "Redo"],
      ["Format", "Font", "FontSize"],
      ["TextColor", "BGColor"],
      ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
      [
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "RemoveFormat",
        "CopyFormatting",
      ],
      ["Link", "Unlink"],
      [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "-",
        "Blockquote",
      ],
      ["Image", "Table"],
      ["Scayt"],
    ],
    extraPlugins: "justify,format,font,colorbutton",
  };
  useEffect(() => {
    axios
      .get(`/api/details/info/662250b4cb31caa88b23e1f5`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setBody(result?.data?.info);
        setSubject(result?.data?.title);
        setShowEditor(true);
      });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      if (errorMessage === "") {
        axios
          .post(
            `/api/send-email`,
            { candidates, content: body, subject, attachments },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            setSent(true);
            setSending(false);
          })
          .catch((err) => {
            setErrorMessage(err.response.data);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper text-left">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{data.title}</h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <>
                          <FormGroup>
                            <h2>Email Subject</h2>
                            <Input
                              type="text"
                              name="subject"
                              value={subject}
                              onChange={(e) => setSubject(e.target.value)}
                            />
                          </FormGroup>
                          <h2>Email Content</h2>
                          {showEditor && (
                            <CKEditor
                              initData={body}
                              onChange={(event) =>
                                setBody(event.editor.getData())
                              }
                              config={editorConfig}
                            />
                          )}
                          <br />
                          <FormGroup>
                            <h2>Attachments</h2>
                            <Select
                              options={[
                                {
                                  label: "Consent Form",
                                  value: "Consent-Form.pdf",
                                },
                              ]}
                              onChange={(e) =>
                                setAttachments(reactSelectChange(e))
                              }
                              isMulti
                            />
                          </FormGroup>
                        </>
                        <br />
                        Please, upload the .CSV file of candidates:
                        <FormGroup row>
                          <Colxx sm="9">
                            <Input
                              type="file"
                              accept=".csv"
                              onChange={(e) => {
                                const files = e.target.files;
                                if (files) {
                                  setCandidatesLoading(true);
                                  Papa.parse(files[0], {
                                    delimiter: ",",
                                    header: true,
                                    skipEmptyLines: true,
                                    complete: function (results) {
                                      // console.log("Finished:");
                                      // console.log(results.data);
                                      setCandidates(results.data);
                                      setCandidatesLoading(false);
                                    },
                                  });
                                }
                              }}
                            />
                          </Colxx>
                        </FormGroup>
                        {candidatesLoading ? (
                          <p>Loading...</p>
                        ) : (
                          <p>The number of candidates: {candidates.length}</p>
                        )}
                        {sending && (
                          <span className="btn btn-warning">Sending...</span>
                        )}
                        {sent && <span className="btn btn-success">DONE!</span>}
                      </Col>
                    </Row>
                  </Form>
                  <div>
                    <Button
                      color="light"
                      className="top-right-button"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className="ml-2 top-right-button"
                      onClick={handleSubmit}
                    >
                      Send
                    </Button>
                  </div>
                  <div>{errorMessage}</div>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
      </div>
    </AppLayout>
  );
};

export default SendEmail;
